import React from 'react';
import { Link } from 'react-router-dom';
import './DashSidebar.css';
import images from '../../../constants/images';

// icons
import { LuLayoutDashboard } from "react-icons/lu";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { RiFileList3Line } from "react-icons/ri";
import { FaRegChartBar } from "react-icons/fa";
import { AiOutlineProfile } from "react-icons/ai";
import { MdOutlinePayments } from "react-icons/md";

function DashSidebar() {
  return (
    <div className="DashSidebar-container">
      <div className="DashSidebar-LogoSection">
        <img src={images.NavbarLogo} alt="CropShield" className='DashSidebar-Logo' />
        <h1 className='DashSidebar-LogoText'>Cropshield.</h1>
      </div>

      <div className="DashSidebar-ListDropdowns-section">
        <Link to="/dashboard" className='DashSidebar-ListDropdowns-Insurance'>
          <button className='DashSidebar-Lists-buttons'>
            <span className='DashSidebar-Lists-buttons-span'><LuLayoutDashboard className='Dashsidebar-list-icons' />Home</span>
          </button>
        </Link>
        <Link to="/dashboard/policies" className='DashSidebar-ListDropdowns-Insurance'>
          <div className="dropdown">
            <button className='DashSidebar-Lists-buttons'>
              <span className='DashSidebar-Lists-buttons-span'><HiOutlineMenuAlt2 className='Dashsidebar-list-icons' />Policies</span>
            </button>
          </div>
        </Link>
        <Link to="/dashboard/pay" className='DashSidebar-ListDropdowns-Insurance'>
          <button className='DashSidebar-Lists-buttons'>
            <span className='DashSidebar-Lists-buttons-span'><MdOutlinePayments className='Dashsidebar-list-icons' />Pay</span>
          </button>
        </Link>
        <Link to="/dashboard/claims" className='DashSidebar-ListDropdowns-Insurance'>
          <button className='DashSidebar-Lists-buttons'>
            <span className='DashSidebar-Lists-buttons-span'><RiFileList3Line className='Dashsidebar-list-icons' />Claims</span>
          </button>
        </Link>
        <Link to="" className='DashSidebar-ListDropdowns-Insurance'>
          <button className='DashSidebar-Lists-buttons'>
            <span className='DashSidebar-Lists-buttons-span'><FaRegChartBar className='Dashsidebar-list-icons' />Reporting</span>
          </button>
        </Link>
        <Link to="/dashboard/profile" className='DashSidebar-ListDropdowns-Insurance'>
          <button className='DashSidebar-Lists-buttons'>
            <span className='DashSidebar-Lists-buttons-span'><AiOutlineProfile className='Dashsidebar-list-icons' />Profile</span>
          </button>
        </Link>
      </div>
      <p className='DashSidebar-Lists-legals'>
        <span className='DashSidebar-Lists-span-legals'><Link to="">Privacy</Link> | <Link to="">Legal</Link></span>
        <span className='DashSidebar-Lists-span-legals'>© 2020-2024 Cropshield. All Rights Reserved</span>
      </p>
    </div>
  );
}

export default DashSidebar;
