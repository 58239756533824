import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PolicyProgress.css'; 
import Dashtitle from '../Dashtitle/Dashtitle';
import { Link } from 'react-router-dom';

const PolicyProgress = ({ userId }) => {
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPolicies();
  }, []);

  const fetchPolicies = async () => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axios.get('https://api.cropinsuranceuaf.com/insurance/manage-policies/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const policiesData = await Promise.all(response.data.map(async (policy) => {
        const premiumDeductible = await fetchPolicyPremiumDeductible(policy.selectPolicy);
        const paymentsMade = await fetchPaymentsMade(policy.id);
        return { ...policy, premium: premiumDeductible.premium, deductible: premiumDeductible.deductible, paymentsMade };
      }));
      setPolicies(policiesData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching policies:', error);
      setLoading(false); 
    }
  };

  const fetchPolicyPremiumDeductible = async (selectedPolicy) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axios.get(`https://api.cropinsuranceuaf.com/insurance/policy-premium-deductible?selectPolicy=${selectedPolicy}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching policy premium and deductible:', error);
      return {};
    }
  };

  const fetchPaymentsMade = async (policyId) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axios.get(`https://api.cropinsuranceuaf.com/insurance/policies/${policyId}/payment-count/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data.paymentMade;
    } catch (error) {
      console.error('Error fetching payments made for policy:', error);
      return 0;
    }
  };

  const handleClaim = (policyId) => {
    console.log(`Policy ${policyId} claimed!`);
  };

  if (loading) {
    return (
      <div className="policy-progress-container">
        <h3>Loading...</h3>
      </div>
    );
  }

  if (policies.length === 0) {
    return (
      <div className="policy-progress-container">
        <h3>No Policies Found</h3>
        <p>You have not applied for any policies yet.</p>
      </div>
    );
  }

  return (
    <>
      <Dashtitle title="Policies Overview" />
      <div className="Policy-Progress-main-container-x">
        {policies.map((policy) => (
          <div className="policy-progress-container" key={policy.id}>
            <h3>{policy.policyName}</h3>
            <div className="policy-details">
              <p>Stay updated on your crop insurance policy progress, ensuring timely coverage and peace of mind for every season.</p>

              <p className="policy-details-p"><span>Policyholder:</span> {policy.farmerName}</p>
              <p className="policy-details-p"><span>Policy:</span> {policy.selectPolicy}</p>
              <p className="policy-details-p"><span>Your Policy Id:</span> {policy.display_id}</p>
              <p className="policy-details-p"><span>Premium Deductible:</span> {policy.premium}</p>
              <p className="policy-details-p"><span>Deductible:</span> {policy.deductible}</p>
              <p className="policy-details-p"><span>Payments Made:</span> {policy.paymentsMade}</p>
              <p className="policy-details-p"><span>Coverage Amount:</span> ${policy.coverageAmount}</p>
              <p className="policy-details-p"><span>Total Paid Amount:</span> ${policy.totalPaidAmount}</p>
              <p className="policy-details-p"><span>Total Paid Amount:</span> ${policy.farmAddress}</p>
              <p className="policy-details-p"><span>Crop Insured:</span> ${policy.cropsInsured}</p>
              <p className="policy-details-p"><span>Acreage Planted:</span> ${policy.acreagePlanted}</p>
              <div className="progress-bar">
                <div className="progress" style={{ width: `${policy.progress}%` }}>
                  <span className="progress-label">{policy.progress}% Complete</span>
                </div>
              </div>
              <div className="payment-history">
                <h4>Payment History</h4>
                <table>
                  <thead>
                    <tr>
                      <th>Installment</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {policy.paymentHistory && policy.paymentHistory.length > 0 ? (
                      policy.paymentHistory.map((payment, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{payment.amount}</td>
                          <td>{payment.date}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3">No Payment History Available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="actions">
                <Link to="/dashboard/claims/newclaim">
                  <button onClick={() => handleClaim(policy.id)} className='policy-progress-claim-button'>Claim</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PolicyProgress;
