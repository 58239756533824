import React, { useState, useEffect } from 'react';
import './DashHomeBlock.css';
import images from '../../../constants/images';

function DashHomeBlock() {
    const [weatherData, setWeatherData] = useState(null);
    const [waterPH, setWaterPH] = useState(null);

    useEffect(() => {
        fetchWeatherData();

        fetchWaterPH();
    }, []);

    const fetchWeatherData = () => {
        const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
        navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=metric`;
            fetch(apiUrl)
                .then(response => response.json())
                .then(data => setWeatherData(data))
                .catch(error => console.error('Error fetching weather data:', error));
        });
    };

    const fetchWaterPH = () => {
        const simulatedWaterPHData = 4.1; 
        setWaterPH(simulatedWaterPHData);
    };

    return (
        <>
            <div className="DashHomeBlock-container">
                <div className="DashHomeBlock-up-div">
                    Be Active for Crops Health
                </div>
                <div className="DashHomeBlock-down-div">
                    <div className="DashHomeBlock-down-div-1">
                        <div className="DashHomeBlock-down-images-div">
                            <img src={images.DashHomeBlock.DashHomeBlockImage1} className="DashHomeBlock-down-images" alt="" />
                        </div>
                        <p className='DashHomeBlock-down-percentage'>
                            {weatherData && weatherData.main ? `${weatherData.main.humidity}%` : 'Loading...'}
                        </p>
                        <p className='DashHomeBlock-down-simple-text'> Humidity percentage</p>
                    </div>
                    <div className="DashHomeBlock-down-div-1">
                        <div className="DashHomeBlock-down-images-div">
                            <img src={images.DashHomeBlock.DashHomeBlockImage2} className="DashHomeBlock-down-images" alt="" />
                        </div>
                        <p className='DashHomeBlock-down-percentage'>
                            {waterPH !== null ? waterPH : 'Loading...'}
                        </p>
                        <p className='DashHomeBlock-down-simple-text'> Daily Water PH</p>
                    </div>
                    <div className="DashHomeBlock-down-div-1">
                        <div className="DashHomeBlock-down-images-div">
                            <img src={images.DashHomeBlock.DashHomeBlockImage3} className="DashHomeBlock-down-images" alt="" />
                        </div>
                        <p className='DashHomeBlock-down-percentage'>
                            {weatherData && weatherData.main ? `${weatherData.main.temp}°C` : 'Loading...'}
                        </p>
                        <p className='DashHomeBlock-down-simple-text'> Today Temperature</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashHomeBlock;
