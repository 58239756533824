import React from 'react'
import { Link } from 'react-router-dom'
import './DashPolicies.css'
import Dashtitle from '../Dashtitle/Dashtitle';
import { BsInfoCircleFill } from "react-icons/bs";
import { FaWpforms } from "react-icons/fa";
import { MdManageSearch } from "react-icons/md";
import { MdAutorenew } from "react-icons/md";

const DashPolicies = () => {
    return (
        <>
            <Dashtitle title="Policies" />
            <div className="DashPolicies-main-container">
                <Link to="/dashboard/policies/apply">
                    <div className="DashPolicies-Box">
                        <div className="DashPolicies-Box-small-icon">
                            <BsInfoCircleFill className="DashPolicies-Box-side-icon" />
                        </div>
                        <div className="DashPolicies-Box-main-icon">
                            <FaWpforms />
                        </div>
                        <div className="DashPolicies-Box-text">
                            <p>Apply Policy</p>
                        </div>
                    </div>
                </Link>
                <Link to="/dashboard/policies/manage">
                    <div className="DashPolicies-Box">
                        <div className="DashPolicies-Box-small-icon">
                            <BsInfoCircleFill className="DashPolicies-Box-side-icon" />
                        </div>
                        <div className="DashPolicies-Box-main-icon">
                            <MdManageSearch />
                        </div>
                        <div className="DashPolicies-Box-text">
                            <p>Manage Policies</p>
                        </div>
                    </div>
                </Link>
                <Link to="/dashboard/policies/renew">
                    <div className="DashPolicies-Box">
                        <div className="DashPolicies-Box-small-icon">
                            <BsInfoCircleFill className="DashPolicies-Box-side-icon" />
                        </div>
                        <div className="DashPolicies-Box-main-icon">
                            < MdAutorenew />
                        </div>
                        <div className="DashPolicies-Box-text">
                            <p>Renew Policies</p>
                        </div>
                    </div>
                </Link>
            </div>

        </>

    )
}

export default DashPolicies