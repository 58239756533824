import React from 'react';
import { Link } from 'react-scroll';
import "./GetStartedOne.css";

const GetStartedOne = () => {
    return (
        <>
            <div className="GetStartedOne-main-container">
                <div className="GetStartedOne-content">
                    <h3 className='GetStartedOne-content-title' id='table-content-One'>Efficiency Redefined</h3>
                    <p className='GetStartedOne-content-text'>At CropShield, efficiency isn't just a goal—it's a core principle that guides everything we do. From the moment data enters our system to the moment actionable insights are delivered, every step is meticulously optimized for speed and accuracy. Our streamlined data processing pipeline eliminates bottlenecks and redundancies, ensuring that you can make informed decisions without delay. <br /><br />
                        By leveraging advanced algorithms and automation techniques, we minimize manual intervention and maximize throughput, enabling you to achieve more in less time. Whether you're analyzing crop health trends, forecasting yield projections, or assessing risk factors, CropShield's efficiency redefines what's possible in agricultural data management.
                    </p>
                    <h3 className='GetStartedOne-content-title' id='table-content-Two'>Automated Intelligence</h3>
                    <p className='GetStartedOne-content-text'>In today's data-driven world, intelligence is the key to unlocking new opportunities and driving innovation. That's why CropShield integrates cutting-edge AI technologies into every aspect of our data processing pipeline. <br /><br />
                        From machine learning algorithms that learn and adapt to your farm's unique characteristics to neural networks that uncover hidden patterns in your data, our automated intelligence capabilities empower you to stay ahead of the curve. By automating repetitive tasks and leveraging the power of predictive analytics, CropShield not only saves you time and effort but also enhances the quality and accuracy of your insights. Whether you're optimizing irrigation schedules, detecting early signs of pest infestations, or identifying optimal planting times, our intelligent algorithms ensure that you're always one step ahead.
                    </p>
                    <h3 className='GetStartedOne-content-title' id='table-content-Three'>Precision at Scale</h3>
                    <p className='GetStartedOne-content-text'>Whether you're a small-scale farmer or a large-scale agribusiness, CropShield scales effortlessly to meet your needs. Our data processing infrastructure is built to handle massive volumes of data with precision and reliability, ensuring consistent results regardless of the size or complexity of your operation. By leveraging cloud-based technologies and distributed computing architectures, we're able to process data at scale without compromising on performance or accuracy. Whether you're managing multiple farms across different regions or monitoring a single crop variety in detail, CropShield's precision at scale ensures that you have the insights you need to succeed.
                    </p>
                    <h3 className='GetStartedOne-content-title' id='table-content-Four'>Real-time Insights</h3>
                    <p className='GetStartedOne-content-text'>In agriculture, timing is everything. That's why CropShield delivers real-time insights that empower you to make informed decisions when it matters most. Whether you're responding to rapidly changing weather conditions, mitigating emerging pest threats, or optimizing resource allocation, our platform provides the timely information you need to stay ahead of the curve. <br /><br />By integrating with weather APIs, satellite imagery, and IoT sensors, CropShield delivers up-to-the-minute insights that enable you to take proactive action and minimize risks. Whether you're in the field or on the go, our real-time insights keep you informed and empowered, no matter where you are.
                    </p>
                </div>
                <div className="GetStartedOne-table-content">
                    <p className='GetStartedOne-table-content-Heading-top'>Table of contents:</p>
                    <Link to="table-content-One"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-Heading'>Streamlined Data Processing</Link>
                    <Link to="table-content-One"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-SubHeading'>Automated Intelligence</Link>
                    <Link to="table-content-Two"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-SubHeading'>Precision at Scale</Link>
                    <Link to="table-content-Three"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-SubHeading'>Real-time Insights</Link>
                </div>
            </div>
        </>
    );
}

export default GetStartedOne;
