// Keep the Picture size 45mm-height and 35mm-width
// import TeamImage from "../assets/Team/TeamImage.png"
import DrSyedMushhadImage from "../assets/Team/Dr. Syed Mushhad.jpg"
import DrSaqibImage from "../assets/Team/Dr. Saqib.jpg"
import FaizanImage from "../assets/Team/FaizanImage.png"
import FaheemImage from "../assets/Team/Faheem.png"
import AkmalRehan from "../assets/Team/AkmalRehan.jpg"

export const teamMembers = [
  {
    id: 1,
    name: 'Dr. Syed Mushhad Mustuzhar Gilani',
    position: 'Principal Investigator',
    contact: 'mushhad@uaf.edu.pk',
    image: DrSyedMushhadImage,
    positionUAF: "Associate Professor"
  },
  {
    id: 2,
    name: 'Dr. Saqib Ali',
    position: 'Co-Principal Investigator',
    contact: 'saqib@uaf.edu.pk',
    positionUAF: "Assistant Professor",
    image: DrSaqibImage
  },
  {
    id: 3,
    name: 'Mr. Akmal Rehan',
    position: 'Team Member',
    contact: 'akmalrehan@uaf.edu.pk',
    positionUAF: "Lecturer",
    image: AkmalRehan
  },
  {
    id: 4,
    name: 'Faizan Ahmad',
    position: 'Lead Developer',
    contact: 'fazitanveer2@gmail.com',
    image: FaizanImage
  },
  {
    id: 5,
    name: 'Mr. Faheem Tariq',
    position: 'Team Member',
    contact: 'faheemtariq231@gmail.com',
    image: FaheemImage
  },
];
