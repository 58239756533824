import React, { useState } from 'react';

const YouTubeVideo = ({ videoId }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handleVideoClick = () => {
    setIsVideoPlaying(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      {isVideoPlaying ? (
        <iframe
          width="640px" 
          height="360px"
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&showinfo=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Video"
          referrerPolicy="strict-origin-when-cross-origin"
        />
      ) : (
        <div
          style={{
            position: 'relative',
            width: '640px', // Initial iframe size before playing
            height: '360px',
            cursor: 'pointer',
          }}
          onClick={handleVideoClick} // Play the video on click
        >
          {/* Load the YouTube iframe without autoplay */}
          <iframe
            width="640px"
            height="360px"
            src={`https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Video Preview"
            referrerPolicy="strict-origin-when-cross-origin"
            style={{ opacity: 0.5 }} // Add transparency to the iframe
          />
          {/* Overlay to detect click */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.3)', // Semi-transparent overlay for better visibility
              color: 'white',
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            <h2 style={{ fontFamily: 'var(--font-base)', fontSize: '26px'}}>
              Click to Play IoT
            </h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default YouTubeVideo;
