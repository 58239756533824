import React, { useState, useEffect, useRef } from 'react';
import './DashHeader.css';
import axios from 'axios';

import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import { RiQuestionMark } from 'react-icons/ri';
import { IoMdNotificationsOutline } from 'react-icons/io';

// Dropdown icons
import { CgProfile } from 'react-icons/cg';
import { IoSettingsOutline } from 'react-icons/io5';
import { CiLogout } from 'react-icons/ci';

function DashHeader() {
  const [userData, setUserData] = useState({
    profile_picture: {
      image: null,
    },
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await axios.get('https://api.cropinsuranceuaf.com/auth/profile-data/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      await axios.post('https://api.cropinsuranceuaf.com/auth/logout/', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      localStorage.removeItem('accessToken');
      window.location.href = '/SignIn';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="DashHeader-container">
      <div className="DashHeader-breadcrumb">
        <Link to="/">
          <FaHome className="DashHeader-Register-page-homeIcon" />
        </Link>
        <span>/ Dashboard</span>
      </div>
      <input className="DashHeader-input" name="text" placeholder="Search..." type="search" />

      <div className="DashHeader-nav-pofile">
        <Link>
          <IoMdNotificationsOutline className="DashHeader-notification-id" />
        </Link>
        <Link to="/Help">
          <RiQuestionMark className="DashHeader-notification-id" />
        </Link>
        <div className="DashHeader-user" ref={dropdownRef}>
          <div onClick={toggleDropdown} onMouseOver={toggleDropdown}>
            {userData.profile_picture && userData.profile_picture.image ? (
              <img
                src={`https://api.cropinsuranceuaf.com${userData.profile_picture.image}`}
                alt={`${userData.first_name} ${userData.last_name}`}
                className="DashHeader-user-img"
              />
            ) : (
              <div className="DashHeader-user-img-placeholder"></div>
            )}
          </div>
          {dropdownOpen && (
            <div className="dropdown-contentt">
              <Link to="/dashboard/profile">
                <CgProfile className="dropdown-contentt-icons" />
                Your Profile
              </Link>
              <Link to="/settings">
                <IoSettingsOutline className="dropdown-contentt-icons" />
                Account Settings
              </Link>
              <hr className="dropdown-contentt-logout" />
              <Link onClick={handleLogout}>
                <CiLogout className="dropdown-contentt-icons" />
                Log out
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DashHeader;
