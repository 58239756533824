import React, { useState, useEffect } from 'react';
import '../DashApplyPolicy/DashApplyPolicy.css';
import axios from 'axios';

const DashApplyClaim = () => {
    const [currentStep, setCurrentStep] = useState(1);
    // UPLOADING IMAGE States START
    const [selectedImage1, setSelectedClaimImage1] = useState(null);
    const [selectedImage2, setSelectedClaimImage2] = useState(null);
    const [selectedImage3, setSelectedClaimImage3] = useState(null);
    const [selectedImage4, setSelectedClaimImage4] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    // UPLOADING IMAGE States END
    const [formData, setFormData] = useState({
        // CLAIM DETAILS
        selectedPolicy: '',
        selectPolicy: '',
        typeOfDamage: '',
        dateOfDamage: '',
        extentOfDamage: '',
        witnessName: '',
        witnessCNIC: '',
        witnessPhoneNumber: '',
        damageDescription: '',

        // SUPPORTING DOCUMENT
        claimPicture1: '',
        claimPicture2: '',
        claimPicture3: '',
        claimPicture4: '',

        // CONTACT DETAILS
        farmerName: '',
        phoneNumber: '',
        email: '',
        address: '',

        // SUBMIT
        agreeTerms: false,
    });

    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        bio: "",
        profile_picture: {
            custom_user: null,
            image: null
        }
    });

    useEffect(() => {
        fetchUserProfile();
    }, []);
    const [userPolicies, setUserPolicies] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchUserPolicies();
    }, []);

    const fetchUserPolicies = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get('https://api.cropinsuranceuaf.com/insurance/user-policies/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setUserPolicies(response.data);
        } catch (error) {
            console.error("Error fetching user policies: ", error);
            setError("Failed to fetch user policies");
        }
    };

    const fetchUserProfile = async () => {
        const token = localStorage.getItem('accessToken');

        try {
            const response = await axios.get('https://api.cropinsuranceuaf.com/auth/profile-data/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const updatedUserData = {
                ...userData,
                ...response.data
            };

            setUserData(updatedUserData);
        } catch (error) {
            console.error("Error fetching user data: ", error);
            setError("Failed to fetch user data");
        }
    };


    const handleChange = (event) => {
        const { name, value, id } = event.target;
        if (name === 'farmLocation') {
            setFormData({
                ...formData,
                farmLocation: {
                    ...formData.farmLocation,
                    [id]: value,
                },
            });
        } else if (name === 'proofOfOwnership' || name === 'additionalDocuments') {
            setFormData({
                ...formData,
                [name]: event.target.files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };



    // FOR PAYMENT CARD NO
    // const handleCardNumberChange = (event) => {
    //     const { value, selectionStart } = event.target;
    //     const cardNumber = value.replace(/\D/g, '');
    //     let formattedCardNumber = '';
    //     for (let i = 0; i < cardNumber.length; i++) {
    //         if (i > 0 && i % 4 === 0) {
    //             formattedCardNumber += ' ';
    //         }
    //         formattedCardNumber += cardNumber[i];
    //     }
    //     const newPosition = selectionStart + Math.floor(selectionStart / 5);
    //     setFormData({
    //         ...formData,
    //         cardNumber: formattedCardNumber
    //     });
    //     event.target.setSelectionRange(newPosition, newPosition);
    // };



    // UPLOADING IMAGE CHANGE MANAGE
    // const handleImageChange = (event, setImage) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         if (file.type === 'image/jpeg' || file.type === 'image/png') {
    //             const reader = new FileReader();
    //             reader.onloadend = () => {
    //                 setImage(reader.result);
    //             };
    //             reader.readAsDataURL(file);
    //         } else {
    //             alert('Invalid file type. Please upload a JPG or PNG image.');
    //         }
    //     }
    // }


    const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

    const minEndDate = new Date();
    minEndDate.setFullYear(minEndDate.getFullYear() + 1);
    const minEndDateISO = minEndDate.toISOString().split('T')[0];
    formData.startDate = today;



    const handleChangePr = async (event) => {
        const { name, value } = event.target;
        if (name === 'selectPolicy') {
            setError("");

            setFormData({
                ...formData,
                [name]: value,
            });

            try {
                const token = localStorage.getItem('accessToken');

                console.log('API URL:', `https://api.cropinsuranceuaf.com/insurance/manage-policies/`);

                const policyResponse = await axios.get(`https://api.cropinsuranceuaf.com/insurance/manage-policies/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                console.log('Policy Response:', policyResponse.data);

                const selectedPolicy = policyResponse.data.find(policy => policy.selectPolicy === value);

                if (selectedPolicy && selectedPolicy.status !== "APPROVED") {
                    setError(`Selected policy '${value}' is not approved. You cannot apply for a claim.`);
                } else if (!selectedPolicy) {
                    setError(`Policy '${value}' not found or you do not have access to it.`);
                }
            } catch (error) {
                console.error('Error validating policy:', error);
                setError("Failed to validate the selected policy.");
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const handleImageChange = (event, setSelectedImage) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            console.error('No file selected');
            return;
        }
        if (file.size > 1024 * 1024 * 5) { // 5 MB limit
            alert('File size too large, maximum 5 MB allowed!');
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        setFormData({
            ...formData,
            [event.target.name]: file,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const token = localStorage.getItem('accessToken');

            const policyResponse = await axios.get(`https://api.cropinsuranceuaf.com/insurance/manage-policies/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log('Policy Response:', policyResponse.data);

            const selectedPolicy = policyResponse.data.find(policy => policy.selectPolicy === formData.selectPolicy);

            if (selectedPolicy && selectedPolicy.status !== "APPROVED") {
                setError(`Selected policy '${formData.selectPolicy}' is not approved. You cannot apply for a claim.`);
                return;
            } else if (!selectedPolicy) {
                setError(`Policy '${formData.selectPolicy}' not found or you do not have access to it.`);
                return;
            }

            const response = await axios.post('https://api.cropinsuranceuaf.com/claims/apply-claim/', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Form submitted successfully:', response.data);
            setFormData({
                selectedPolicy: '',
                selectPolicy: '',
                typeOfDamage: '',
                dateOfDamage: '',
                extentOfDamage: '',
                witnessName: '',
                witnessCNIC: '',
                witnessPhoneNumber: '',
                damageDescription: '',
                claimPicture1: '',
                claimPicture2: '',
                claimPicture3: '',
                claimPicture4: '',
                farmerName: '',
                phoneNumber: '',
                email: '',
                address: '',
                agreeTerms: false,
            });
            setIsSubmitted(true); 
            setError(''); 
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsSubmitted(false); 
            setError('Failed to submit the form'); 
        }
    };
    
    const nextStep = () => {
        const requiredFields = document.querySelectorAll('.form-group.active [required]');
        let isValid = true;
        requiredFields.forEach(field => {
            if (!field.value.trim()) {
                isValid = false;
                setError('Please fill out all required fields.');
            }
        });

        if (isValid) {
            setCurrentStep(currentStep + 1);
            setError('');
        }
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
        setError('');
    };

    const renderSection = (step) => {
        switch (step) {
            case 1:
                //  -------------Claim Details STARTS--------------- 
                return (
                    <div className="form-group farmer-info active">
                        <p className='apply-policy-form-section-headings'>Claim Details</p>
                        <label htmlFor="selectPolicy">Select Policy <span>*</span> <br /> <small>(Carefully select the policy in which you want to enroll before applying)</small></label>
                        <select
                            name="selectPolicy"
                            id="selectPolicy"
                            value={formData.selectPolicy}
                            onChange={handleChangePr}
                            className="form-input"
                            required
                        >
                            <option value="" disabled>Select Policy</option>
                            <option value="HarvestGuard_Assurance">HarvestGuard Assurance</option>
                            <option value="CropShield_Secure">CropShield Secure</option>
                            <option value="AgriGuard_Plus">AgriGuard Plus</option>
                            <option value="FarmShield_Complete">FarmShield Complete</option>
                            <option value="CropSafe_Prime">CropSafe Prime</option>

                        </select>
                        {error && <p className="error-message">{error}</p>}

                        <label htmlFor="selectedPolicy">Selected Policy:</label> 
                        <select
                            name="selectedPolicy" 
                            id="selectedPolicy" 
                            value={formData.selectedPolicy} 
                            onChange={handleChange}
                        >
                            <option value="">Select Policy</option>
                            {userPolicies.map(policy => (
                                <option key={policy.display_id} value={policy.display_id}>{policy.display_id}</option>
                            ))}
                        </select>
                        {error && <p className="error-message">{error}</p>}

                        <label htmlFor="typeOfDamage">Type of Damage <span>*</span></label>
                        <select
                            name="typeOfDamage"
                            id="typeOfDamage"
                            value={formData.typeOfDamage}
                            onChange={handleChange}
                            className="form-input"
                            required
                        >
                            <option value="">Select Type of Damage</option>
                            <option value="weather_related">Weather-related</option>
                            <option value="pest_related">Pest-related</option>
                            <option value="disease_related">Disease-related</option>
                            <option value="mechanical_damage">Mechanical damage</option>
                        </select>
                        <label htmlFor="dateOfDamage">Date of Damage Occurrence <span>*</span></label>
                        <input
                            type="date"
                            name="dateOfDamage"
                            id="dateOfDamage"
                            value={formData.dateOfDamage}
                            onChange={handleChange}
                            required
                            min="2024-01-01"
                            max={new Date().toISOString().split("T")[0]}
                            placeholder="Select the date of damage"
                        />

                        <label htmlFor="extentOfDamage">Extent of Damage <span>*</span></label>
                        <select
                            name="extentOfDamage"
                            id="extentOfDamage"
                            value={formData.extentOfDamage}
                            onChange={handleChange}
                            className="form-input"
                            required
                        >
                            <option value="">Select Extent of Damage</option>
                            <option value="0-25%">0-25% Crop Loss</option>
                            <option value="26-50%">26-50% Crop Loss</option>
                            <option value="51-75%">51-75% Crop Loss</option>
                            <option value="76-100%">76-100% Crop Loss</option>
                        </select>

                        <label htmlFor="witnessName">witness Name <span>*</span><br /><small>(As per your CNIC)</small></label>
                        <input
                            type="text"
                            name="witnessName"
                            id="witnessName"
                            value={formData.witnessName}
                            onChange={handleChange}
                            required
                            placeholder="e.g., Faizan Ahmad"
                            maxlength="50"
                        />


                        <label htmlFor="witnessCNIC">Witness CNIC <span>*</span><br /><small>(Ex: XXXXXXXXXXXXX)</small></label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            name="witnessCNIC"
                            id="witnessCNIC"
                            value={formData.witnessCNIC}
                            onChange={handleChange}
                            required
                            maxLength="13"
                            minLength="13"
                            placeholder="e.g., 3310088776665"
                        />

                        <label htmlFor="witnessPhoneNumber">Witness Contact Number <span>*</span></label>
                        <div class="phone-input">
                            <select
                                name="countryCode"
                                id="countryCode"
                                value={formData.countryCode}
                                onChange={handleChange}
                                required
                                class="select2"
                            >
                                <option value="+1">+1 (USA)</option>
                                <option value="+44">+44 (UK)</option>
                                <option value="+92">+92 (Pakistan)</option>
                                <option value="+970">+970 (Palestine)</option>
                                <option value="+20">+20 (Egypt)</option>
                                <option value="+962">+962 (Jordan)</option>
                                <option value="+971">+971 (UAE)</option>
                            </select>
                            <input
                                type="tel"
                                name="witnessPhoneNumber"
                                id="witnessPhoneNumber"
                                value={formData.witnessPhoneNumber}
                                onChange={handleChange}
                                required
                                maxLength="10"
                                placeholder="Enter witness phone number (e.g., 1234567890)"
                            />

                        </div>
                        <label htmlFor="damageDescription">Description of Damage <span>*</span><br /><small>(Please be concise yet detailed)</small></label>
                        <textarea
                            name="damageDescription"
                            id="damageDescription"
                            value={formData.damageDescription}
                            onChange={handleChange}
                            required
                            minLength="20"
                            maxLength="500"
                            rows="4"
                            placeholder="Enter a concise yet detailed description of the damage"
                        ></textarea>
                    </div>
                );
            //  -------------Claim Details END--------------- 
            case 2:
                //  ------------- Contact Details STARTS---------------
                return (
                    <div className="form-group farmer-info active">
                        <p className='apply-policy-form-section-headings'>Contact Details</p>
                        <label htmlFor="farmerName">Your Name <span>*</span><br /><small>(Cropshield registered name)</small></label>
                        <input
                            type="text"
                            name="farmerName"
                            id="farmerName"
                            value={formData.farmerName}
                            onChange={handleChange}
                            required
                            placeholder="e.g., Faizan Ahmad"
                            maxlength="50"
                        />

                        <label htmlFor="phoneNumber">Contact Number <span>*</span></label>
                        <div class="phone-input">
                            <select
                                name="countryCode"
                                id="countryCode"
                                value={formData.countryCode}
                                onChange={handleChange}
                                required
                                class="select2"
                            >
                                <option value="+1">+1 (USA)</option>
                                <option value="+44">+44 (UK)</option>
                                <option value="+92">+92 (Pakistan)</option>
                                <option value="+970">+970 (Palestine)</option>
                                <option value="+20">+20 (Egypt)</option>
                                <option value="+962">+962 (Jordan)</option>
                                <option value="+971">+971 (UAE)</option>
                            </select>
                            <input
                                type="tel"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                                maxLength="10"
                                placeholder="Enter your phone number (e.g., 1234567890)"
                            />
                        </div>
                        <label htmlFor="email">Email <span>*</span></label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            // disabled
                            required
                            placeholder="Enter your email address"
                        />
                    </div>
                );
            //  ------------- Contact Details END---------------
            // --------------- SUPPORTING DOCUMENT STARTS ----------
            case 3:
                return (
                    <div className="form-group crop-info active">
                        <p className='apply-policy-form-section-headings'>Supporting Document</p>
                        <div>
                            <div className="upload-picture">
                                <label htmlFor="claimPicture1" className="upload-label">Upload Picture of the Entire Crop Field<span>*</span><br /><small>(Capture the entire crop field)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="claimPicture1"
                                    name="claimPicture1"
                                    onChange={(event) => handleImageChange(event, setSelectedClaimImage1)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage1 && (
                                    <div className="image-preview">
                                        <img src={selectedImage1} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>
                            <div className="upload-picture">
                                <label htmlFor="claimPicture2" className="upload-label">Upload Close-up Picture of Damaged Crop<span>*</span><br /><small>(Focus on the damaged crops)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="claimPicture2"
                                    name="claimPicture2"
                                    onChange={(event) => handleImageChange(event, setSelectedClaimImage2)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage2 && (
                                    <div className="image-preview">
                                        <img src={selectedImage2} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>
                            <div className="upload-picture">
                                <label htmlFor="claimPicture3" className="upload-label">Upload Picture of Pests or Disease Symptoms<span>*</span><br /><small>(Show pests or symptoms of disease)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="claimPicture3"
                                    name="claimPicture3"
                                    class="claimPicture3"
                                    onChange={(event) => handleImageChange(event, setSelectedClaimImage3)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage3 && (
                                    <div className="image-preview">
                                        <img src={selectedImage3} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>
                            <div className="upload-picture">
                                <label htmlFor="claimPicture4" className="upload-label">Upload Picture of Nearby Environmental Conditions<span>*</span><br /><small>(Show environmental conditions around the crop field)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="claimPicture4"
                                    class="claimPicture4"
                                    name="claimPicture4"
                                    onChange={(event) => handleImageChange(event, setSelectedClaimImage4)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage4 && (
                                    <div className="image-preview">
                                        <img src={selectedImage4} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="form-group payment-info">
                            <p className='apply-policy-form-section-headings'>Submit</p>
                        </div>

                        <p className='apply-claim-submit-title'>Declaration of Accuracy</p>
                        <p className='apply-claim-submit-text'>By submitting this claim, you affirm that all information provided is true and accurate to the best of your knowledge. Any discrepancies or inaccuracies may result in delays or rejection of your claim. We rely on your honesty and cooperation to ensure fair and efficient processing. <br /><br />You acknowledge your responsibility to ensure the integrity and authenticity of the submitted documentation and agree to cooperate with any verification processes deemed necessary by CropShield. Providing fraudulent information may result in the forfeiture of insurance benefits and potential legal action.</p>
                        <p></p>
                        <div className="checkbox-wrapper-46">
                            <input
                                type="checkbox"
                                id="cbx-46"
                                className="inp-cbx"
                                required
                            />
                            <label htmlFor="cbx-46" className="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check-box-text-field'>I acknowledge that all information provided in this claim submission is true, accurate, and complete to the best of my knowledge. I understand that any false or misleading information may result in the rejection of my claim and could lead to legal consequences. By checking this box, I affirm my commitment to honesty and transparency in the claims process.</span>
                            </label>
                        </div>


                    </div>


                );
            // --------------- SUPPORTING DOCUMENT END ------------------
            default:
                return null;
        }
    };

    return (
        <>
            <div className="ApplyPolicy-main-container">
                <form className="apply-policy-form" onSubmit={handleSubmit}>
                    {/* --------------TOP SECTIONS DISPLAY---------------- */}
                    <div className="apply-policy-section-name">
                        <ul>
                            <li className={currentStep === 1 ? 'active' : ''} onClick={() => setCurrentStep(1)}>
                                CLAIM DETAILS
                                {currentStep > 1 && <span className="icon">{'>'}</span>}
                            </li>
                            <li className={currentStep === 2 ? 'active' : ''} onClick={() => setCurrentStep(2)}>
                                CONTACT DETAILS
                                {currentStep > 2 && <span className="icon">{'>'}</span>}
                            </li>
                            <li className={currentStep === 3 ? 'active' : ''} onClick={() => setCurrentStep(3)}>
                                SUPPORTING DOCUMENT
                            </li>

                        </ul>
                    </div>
                    {/* --------------CONTROL BUTTONS---------------- */}
                    {renderSection(currentStep)}
                    {isSubmitted && (
                        <p className="success-message" id='form-message'>Form submitted successfully!</p>
                    )}
                    {error && <p className="error-message">{error}</p>}
                    <div className="form-buttons">
                        {currentStep !== 1 && (
                            <button type="button" onClick={prevStep} id='apply-policy-previous-button'>Previous</button>
                        )}
                        {currentStep < 3 && (
                            <button type="submit" onClick={nextStep} id='apply-policy-next-button'>Next</button>
                        )}
                        {currentStep === 3 && (
                            <button type="submit">Submit</button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default DashApplyClaim;
