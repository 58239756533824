import React, { useState } from 'react';
import "./index.css"
// icons
import { RxHamburgerMenu } from "react-icons/rx";
// ____________DASHBOARD COMPONENTS___________
import DashSidebar from './DashSidebar/DashSidebar';
import DashHeader from './DashHeader/DashHeader';
// Dashboard Home Page
import DashboardHomeHeader from './DashboardHomeHeader/DashboardHomeHeader';
import DashHomeBlock from './DashHomeBlock/DashHomeBlock';
import DashSelection from './DashSelection/DashSelection';

// Insurace Pages
import DashApplyPolicy from './DashApplyPolicy/DashApplyPolicy';
import AppliedPolicy from './AppliedPolicy/AppliedPolicy'
import PolicyProgress from "./PolicyProgress/PolicyProgress";
import ManagePolicies from './ManagePolicies/ManagePolicies';
import ApplyPolicyPage from './ApplyPolicyPage/ApplyPolicyPage';

//Policies Page
import DashPolicies from './DashPolicies/DashPolicies';
import Dashtitle from './Dashtitle/Dashtitle';

// Claims
import DashClaims from './DashClaims/DashClaims';
import DashApplyClaim from './DashApplyClaim/DashApplyClaim';
import ClaimStatus from './ClaimStatus/ClaimStatus';
import ClaimHistory from './ClaimHistory/ClaimHistory';

// Pay
import PaymentPage from './PaymentPage/PaymentPage';
import ProfileBlock from './ProfileBlock/ProfileBlock';

function DashboardLayout() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`} >
                <DashHeader />
                <div id="dash-main-content-id">
                    <DashboardHomeHeader />
                    < DashHomeBlock />
                </div>
                <DashSelection />
                {/* <ManagePolicies /> */}
            </div>
        </div>
    );
}

// -------------------POLICIES--------------------
function DashboardLayoutPolicies() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <DashPolicies />
                <ApplyPolicyPage />
            </div>
        </div>
    );
}
// -------------------APPLY-POLICY--------------------
function DashboardLayoutApplyPolicy() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <Dashtitle title="Apply Policy" />
                {/* <ApplyPolicyPage/> */}
                <DashApplyPolicy />
            </div>
        </div>
    );
}
// -------------------APPLIED-POLICY--------------------
function DashboardAppliedPolicy() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <AppliedPolicy />
            </div>
        </div>
    );
}
// -------------------ManagePolicy--------------------
function DashboardManagePolicy() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <ManagePolicies />

            </div>
        </div>
    );
}
// -------------------PROGRESS--------------------
function DashboardProgress() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <PolicyProgress />

            </div>
        </div>
    );
}

// -------------------CLAIM--------------------
function DashboardClaim() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <DashClaims />
                {/* <ApplyPolicyPage /> */}
            </div>
        </div>
    );
}
// -------------------Submit New Claim--------------------
function DashboardNewClaim() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <Dashtitle title="Apply Claim" />
                <DashApplyClaim />

            </div>
        </div>
    );
}

// ------------------- Claim Status--------------------
function DashboardClaimStatus() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <ClaimStatus />

            </div>
        </div>
    );
}
// ------------------- Claim Status--------------------
function DashboardClaimHistory() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <ClaimHistory />

            </div>
        </div>
    );
}
// ------------------- Pay--------------------
function DashboardPay() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <Dashtitle title="Secure Payment Transfer" />
                <PaymentPage />
            </div>
        </div>
    );
}
// ------------------- Profile--------------------
function DashboardProfile() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="dashboard-container">
            <div className={`DashSideBar-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
                <div className="dashSidebar-toggle-button" onClick={toggleSidebar}>
                    <RxHamburgerMenu />
                </div>
                {isSidebarOpen && <DashSidebar />}
            </div>
            <div className={`dash-main-content ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                <DashHeader />
                <Dashtitle title="Your Profile" />
                <ProfileBlock />
            </div>
        </div>
    );
}



export { DashboardLayout, DashboardLayoutPolicies, DashboardLayoutApplyPolicy, DashboardAppliedPolicy, DashboardProgress, DashboardManagePolicy, DashboardClaim, DashboardNewClaim, DashboardClaimStatus, DashboardClaimHistory, DashboardPay, DashboardProfile };
