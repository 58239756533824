// import all Gallery Images at here
import GalleryOneImage from "../assets/Gallery/GalleryOne.jpg"
import GalleryTwoImage from "../assets/Gallery/GalleryTwo.jpg"
import GalleryThreeImage from "../assets/Gallery/GalleryThree.jpg"

export const GalleryData = [
  {
    id: 0,
    title: 'Annual Project Exhibition',
    text: 'Exhibition center March 2024',
    image: GalleryOneImage,
  },
  {
    id: 1,
    title: 'Annual Project Exhibition',
    text: 'Exhibition center March 2023',
    image: GalleryTwoImage,
  },
  {
    id: 2,
    title: 'Project Inspection',
    text: 'CS Department, University of Agriculture',
    image: GalleryThreeImage,
  },

]

export default GalleryData;