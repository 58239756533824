import React from 'react';
import { Link } from 'react-scroll';
import "./GetStartedOne.css";

const GetStartedTwo = () => {
    return (
        <>
            <div className="GetStartedOne-main-container">

                <div className="GetStartedOne-content">
                    <h3 className='GetStartedOne-content-title' id='table-content-One'>Security Reinvented</h3>
                    <p className='GetStartedOne-content-text'>Welcome to BlockChain Assurance, where security meets transparency. Our platform harnesses the power of blockchain technology to revolutionize how we ensure trust and integrity in agricultural operations. From monitoring crop health to managing supply chains, every aspect of our platform is meticulously designed for maximum security and reliability, empowering you to make informed decisions with confidence.<br /><br />
                        By employing advanced blockchain protocols, we establish immutable records and tamper-proof transactions, setting new standards for security and transparency in agricultural data management.
                    </p>
                    <h3 className='GetStartedOne-content-title' id='table-content-Two'>Smart Contracts Integration</h3>
                    <p className='GetStartedOne-content-text'>In today's data-driven world, smart contracts are transforming how transactions are executed and verified. That's why BlockChain Assurance seamlessly integrates smart contract technology into every facet of our platform. <br /><br />
                        From automating payment processes to verifying compliance with regulatory standards, our smart contract integration empowers you to streamline operations and enhance efficiency. By automating complex tasks and ensuring trustless transactions, BlockChain Assurance not only saves you time and resources but also elevates the security and reliability of your operations. Whether you're managing supply chains or executing financial transactions, our smart contracts ensure that you're always in control.
                    </p>
                    <h3 className='GetStartedOne-content-title' id='table-content-Three'>Decentralized Data Management</h3>
                    <p className='GetStartedOne-content-text'>Whether you're a small-scale farmer or a global agribusiness, BlockChain Assurance offers decentralized data management solutions to meet your needs. Our platform is built on decentralized networks, ensuring that your data remains secure and accessible, regardless of the size or complexity of your operation. <br /><br />
                        By leveraging distributed ledger technology and decentralized architectures, we provide you with the assurance that your data is protected from unauthorized access and tampering. Whether you're storing sensitive information or tracking supply chain transactions, BlockChain Assurance's decentralized data management ensures that your data remains safe and secure at all times.
                    </p>
                    <h3 className='GetStartedOne-content-title' id='table-content-Four'>Immutable Records</h3>
                    <p className='GetStartedOne-content-text'>In agriculture, data integrity is paramount. That's why BlockChain Assurance leverages immutable records to ensure the integrity and authenticity of your data. Our platform utilizes blockchain technology to create a tamper-proof audit trail of all transactions, providing you with a transparent and verifiable record of your operations. <br /><br />By maintaining immutable records, BlockChain Assurance enables you to track the provenance of your products, verify compliance with regulatory standards, and ensure the integrity of your data. Whether you're tracking shipments or verifying certifications, our immutable records ensure that your data remains trustworthy and reliable.
                    </p>


                </div>
                <div className="GetStartedOne-table-content">
                    <p className='GetStartedOne-table-content-Heading-top'>Table of contents:</p>
                    <Link to="table-content-One"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-Heading'>Security Reinvented</Link>
                    <Link to="table-content-One"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-SubHeading'>Smart Contracts Integration</Link>
                    <Link to="table-content-Two"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-SubHeading'>Decentralized Data Management</Link>
                    <Link to="table-content-Three"
                        smooth={true}
                        duration={500}
                        className='GetStartedOne-table-content-SubHeading'>Immutable Records</Link>
                </div>

            </div>
        </>
    );
}

export default GetStartedTwo;
