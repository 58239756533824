import React from 'react'
import "./ApplyPolicyPage.css"
import images from '../../../constants/images'
import { Link } from 'react-router-dom'

const ApplyPolicyPage = () => {
    return (
        <>
            <p className='ApplyPolicyPage-title-text'>Boost your farm with state-of-the-art insurance solutions. Explore discounts and free trials for leading agricultural technologies.</p>
            <div className="ApplyPolicyPage-container-main">
                <div className="ApplyPolicyPage-top-left">
                    <p className='ApplyPolicyPage-top-left-title'>
                        Insurance Policies Pro
                    </p>
                    {/* CropSure */}
                    <p className='ApplyPolicyPage-top-left-text'>
                        With Cropshield, you're investing in peace of mind. We protect your crops ,and your investment.
                    </p>
                    <Link to={"/InsurancePolicies"} className='ApplyPolicyPage-link-main'>Explore Policies</Link>
                </div>

                <div className="ApplyPolicyPage-top-right">
                    <img src={images.Dashboard.Dashboardapplypolicypage} alt="sidephoto" />
                </div>

            </div>
        </>
    )
}

export default ApplyPolicyPage