import React, { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import './FAQs.css';
import Headings from '../Headings/Headings';

const FAQs = () => {
    const [faqs, setFaqs] = useState([

        {
            "question": "What is CropShield?",
            "answer": "CropShield is an innovative digital platform designed to revolutionize crop insurance management. It offers farmers a user-friendly interface for purchasing, managing, and accessing crop insurance policies efficiently.",
            "isOpen": false
        },
        {
            "question": "How does CropShield benefit farmers?",
            "answer": "CropShield empowers farmers by simplifying the complexities of crop insurance. It provides swift and accurate claims processing through artificial intelligence, ensuring timely support in the event of crop damage or yield loss. By enhancing resilience in agriculture, CropShield safeguards the livelihoods of farmers and contributes to sustainable food production.",
            "isOpen": false
        },
        {
            "question": "What user roles are available in CropShield?",
            "answer": "CropShield defines three distinct user roles: farmers, insurance providers, and administrators. Each role has specific permissions and functionalities tailored to their responsibilities. Farmers can purchase, customize, and manage crop insurance policies, while insurance providers have access to policy data, claims information, and system analytics. Administrators oversee the overall operation of the system.",
            "isOpen": false
        },
        {
            "question": "How secure is CropShield?",
            "answer": "CropShield prioritizes the security of user data and implements robust measures to protect confidentiality and integrity. It encrypts sensitive information during transmission, complies with industry security standards for authentication processes, and ensures compliance with data protection regulations such as GDPR. Regular security audits and updates are conducted to maintain the platform's security posture.",
            "isOpen": false
        },
        {
            "question": "Can farmers access real-time weather updates through CropShield?",
            "answer": "Yes, CropShield integrates with weather APIs to provide farmers with real-time weather updates relevant to their geographical location. This feature enables farmers to make informed decisions about their crops and anticipate potential risks, enhancing their ability to manage agricultural operations effectively.",
            "isOpen": false
        },
        {
            "question": "How does CropShield ensure system availability?",
            "answer": "CropShield ensures 24/7 availability for its users by implementing robust infrastructure and scheduling maintenance or updates during off-peak hours. Users are notified in advance of any scheduled downtime to minimize disruptions. Additionally, the platform's scalability and redundancy measures contribute to maintaining uninterrupted service.",
            "isOpen": false
        }

    ]);

    const toggleFAQ = (index) => {
        setFaqs(
            faqs.map((faq, i) => (i === index ? { ...faq, isOpen: !faq.isOpen } : { ...faq, isOpen: false }))
        );
    };

    return (
        <>
            <Headings title="Frequently Asked Questions" text="
By
'Faizan Ahmad'
Edited by
'Dr. Saqib Ali'
Reviewed by
'Dr. Syed Mushhad'
Updated Apr 19, 2024"/>
            <div className="faq-container">
                <div className="faq-list">
                    {faqs.map((faq, index) => (
                        <div className="faq-item" key={index}>
                            <h3 className="faq-question" onClick={() => toggleFAQ(index)}>
                                {faq.question}
                                <span className="faq-icon">
                                    {faq.isOpen ? (
                                        <IoIosArrowUp />
                                    ) : (
                                        <IoIosArrowDown />
                                    )}
                                </span>
                            </h3>
                            {faq.isOpen && <p className="faq-answer">{faq.answer}</p>}
                            <div className='faqs-hr' /><div />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FAQs;
