// NavBar
import NavbarLogo from "../assets/NavBar/Navbar-Logo.png"
import SlideImage from "../assets/SlideImage/slideImage.jpg"
import NavbarLogoUni from "../assets/NavBar/Navbar-Logo-uni.png"

// OFFERS
import OfferLogoTwo from "../assets/OfferLogoIcons/2.png"
import OfferLogoThree from "../assets/OfferLogoIcons/3.png"
import OfferLogoFour from "../assets/OfferLogoIcons/4.png"
import OfferLogoFive from "../assets/OfferLogoIcons/5.png"
import OfferLogoSix from "../assets/OfferLogoIcons/6.png"

// Home Hjoin
import HjoinImage from "../assets/Hjoin/img1.jpg"

// repo Images 
import RepoImage1 from "../assets/RepoImages/s1.jpg"
import RepoImage2 from "../assets/RepoImages/s2.png"
import RepoImage3 from "../assets/RepoImages/s4.png"
import RepoImage4 from "../assets/RepoImages/s5.png"
import RepoImage5 from "../assets/RepoImages/s6.png"

// Register
import RegisterImageOne from "../assets/RegisterPage/ImageOneIs.png"
import RegisterImageTwo from "../assets/RegisterPage/okies.png"
// Support & Help
import SupportImageOne from "../assets/HelpAndSupport/supportOne.jpg"

// AboutUs
import AboutUsImageOne from "../assets/AboutUs/Grain1.jpeg"

// PoliciesPage
import PoliciesPageImage1 from "../assets/PoliciesPage/PoliciesPageImage1.jpg"
import PoliciesPageImage2 from "../assets/PoliciesPage/PoliciesPageImage2.png"

// InsurancePolicies/Stream-Process
import StreamProcessImage from "../assets/OtherPages/GettingStared.png"
// Other pages
import BackgroundHeaderImage from "../assets/New/image1.png"
// Features
import FeatureImageOne from "../assets/Features/img-1.png"
import FeatureImageTwo from "../assets/Features/img-2.png"
import FeatureImageThree from "../assets/Features/img-3.png"
//Dashboard-images-home
import DashHomeBlockImage1 from '../assets/DashboardHome/noun-agriculture.png'
import DashHomeBlockImage2 from '../assets/DashboardHome/noun-water.png'
import DashHomeBlockImage3 from '../assets/DashboardHome/noun-weather.png'
import Dashboardapplypolicypage from '../assets/Dashboard/dashboard applyy.jpg'
import PaymentPageIcon from "../assets/Dashboard/paymentpageicon.jpg"
import PaymentMethodIcon from "../assets/payment-icons-1.png"

import USDAImage from "../assets/Endowment Fund Secretariat.png"

const images = {
    NavbarLogo, SlideImage, NavbarLogoUni, HjoinImage, BackgroundHeaderImage, USDAImage,
    Offer: {
        OfferLogoTwo, OfferLogoThree, OfferLogoFour, OfferLogoFive, OfferLogoSix,
    },
    DashHomeBlock: {
        DashHomeBlockImage1, DashHomeBlockImage2, DashHomeBlockImage3,
    },
    Support: {
        SupportImageOne,
    },
    AboutUs: {
        AboutUsImageOne,
    },
    StreamProcess: {
        StreamProcessImage,
    },
    PoliciesPage: {
        PoliciesPageImage1, PoliciesPageImage2,
    },
    Features: {
        FeatureImageOne, FeatureImageTwo, FeatureImageThree,
    },
    Register: {
        RegisterImageOne, RegisterImageTwo
    },
    RepoImages: {
        RepoImage1, RepoImage2, RepoImage3, RepoImage4, RepoImage5
    },
    Dashboard: {
        Dashboardapplypolicypage, PaymentPageIcon, PaymentMethodIcon
    },
}
export default images