import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoutes from './PrivateRoute';
// Import components
import SplashScreen from './components/SplashScreen/SplashScreen';
import ScrollTopButton from './components/ScrollToTop/ScrollToTop';
import Navbar from './components/Navbar/Navbar';
import Carousel from './components/Carousel/Carousel';
import SubHeading from './components/SubHeading/SubHeading';
import OfferLogo from './components/OfferLogo/OfferLogo';
import Features from './components/Features/Features';
import Hjoin from './components/Hjoin/Hjoin';
import FAQs from './components/FAQs/FAQS';
import GetInTouch from './components/GetInTouch/GetInTouch';
import Footer from './components/Footer/Footer';
import GalleryMain from './components/Gallery/GalleryMain';
import RegisterPage from './components/RegisterPage/RegisterPage';
import SignInPage from './components/SignInPage/SignInPage';
import ResetPasswordPage from './components/ResetEmail/ResetEmail';
import ResetPasswordEmailPage from './components/ResetPassword/ResetPassword';
import HeaderBlock from './components/HeaderBlock/HeaderBlock';
import Headings from './components/Headings/Headings';
import AnimeCounter from './components/AnimeCounter/AnimeCounter';
import YouTubeVideo from './components/YouTubeVideo/YouTubeVideo'
import Team from './components/Team/Team';
import UsdaCom from './components/UsdaCom/UsdaCom';
import PageHeader from './components/PageHeader/PageHeader';
import StreamlineData from './components/StreamlineData/StreamlineData';

import PoliciesCards from './components/PoliciesCards/PoliciesCards';
import GetStartedOne from './components/GetStartedOne/GetStartedOne';
import GetStartedTwo from './components/GetStartedOne/GetStartedTwo';
import SupportHeader from './components/SupportHeader/SupportHeader';
import {
  DashboardLayout, DashboardLayoutPolicies, DashboardLayoutApplyPolicy, DashboardAppliedPolicy,
  DashboardProgress, DashboardManagePolicy, DashboardClaim, DashboardNewClaim, DashboardClaimStatus,
  DashboardClaimHistory, DashboardPay, DashboardProfile
} from './components/Dashboard/index';
import images from './constants/images';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); 
  }, []);

  return (
    <>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <BrowserRouter>
          <Routes>
            {/* Unauthenticated routes */}
            <Route path="/" element={<><div className='HOME'><Navbar /><Carousel /> <SubHeading hint="AI and Blockchain-Based Crop Insurance System" title="Shielding Your Harvest, Securing Your Future" text="Sow the seeds of security with CropShield, your digital ally in the fields. Seamlessly merging AI prowess with agricultural expertise, CropShield delivers swift and precise insurance solutions, empowering farmers to nurture their crops with unwavering assurance. Whether it's tilling the soil, nurturing fresh fruits, cultivating vegetables, or harvesting awesome wheats, CropShield stands as the guardian of your agricultural endeavors, ensuring resilience and prosperity with every harvest." />
              <OfferLogo /><Features /> <Hjoin /><FAQs /><GetInTouch /><Footer /></div> <ScrollTopButton /></>} />
            <Route path="/home" element={<><div className='HOME'><Navbar /><Carousel /> <SubHeading hint="AI and Blockchain-Based Crop Insurance System" title="Shielding Your Harvest, Securing Your Future" text="Sow the seeds of security with CropShield, your digital ally in the fields. Seamlessly merging AI prowess with agricultural expertise, CropShield delivers swift and precise insurance solutions, empowering farmers to nurture their crops with unwavering assurance. Whether it's tilling the soil, nurturing fresh fruits, cultivating vegetables, or harvesting awesome wheats, CropShield stands as the guardian of your agricultural endeavors, ensuring resilience and prosperity with every harvest." />
              <OfferLogo /><Features /> <Hjoin /><FAQs /><GetInTouch /><Footer /></div> <ScrollTopButton /></>} />
            <Route path="/AboutUs" element={<><div className='ABOUTUS'><Navbar /><HeaderBlock /><Headings title="Meet Our Executive Leadership." text="Meet the visionary minds leading our team towards revolutionizing crop insurance with innovative technology and a commitment to agricultural resilience." /> <Team /> <AnimeCounter /> <Headings title="Explore Our Mission." text="From rural enthusiasts to seasoned agriculturists, and from local co-ops to global enterprises, our community leverages CropShield to enhance crop management, mitigate risks, and propel agricultural innovation." linkText="Learn more about our purpose" /> <UsdaCom /> <GetInTouch /> <Footer /></div> <ScrollTopButton /></>} />
            
            <Route path="/AboutUsGallery" element={<><div className='StreamProcessImage'><Navbar /><PageHeader title="Our Gallery" image={images.StreamProcess.StreamProcessImage} /> <Headings title="IoT based precise crop risk assessment system"/> <YouTubeVideo videoId="Qi8KCqJr_3U" /> <Headings title="Meet Our Executive Leadership." text="Meet the visionary minds leading our team towards revolutionizing crop insurance with innovative technology and a commitment to agricultural resilience." /> <GalleryMain /> <Footer /></div> <ScrollTopButton /></>} />
            
            <Route path="/InsurancePolicies" element={<><div className='InsurancePolicies'><Navbar /><PageHeader title="Our Comprehensive Insurance Policies" image={images.BackgroundHeaderImage} /><PoliciesCards /><GetInTouch /> <Footer /></div> <ScrollTopButton /></>} />
            <Route path="/Stream-Process" element={<><div className='StreamProcessImage'><Navbar /><PageHeader title="Streamlined Data Processing" image={images.StreamProcess.StreamProcessImage} /> <StreamlineData/><GetStartedOne /><Footer /></div> <ScrollTopButton /></>} />
            <Route path="/Blockchain-Int" element={<><div className='StreamProcessImage'><Navbar /><PageHeader title="BlockChain Assurance" image={images.StreamProcess.StreamProcessImage} /> <GetStartedTwo /><Footer /></div> <ScrollTopButton /></>} />
            <Route path="/help" element={<><div className='SUPPORT'><Navbar /> <SupportHeader title="Help & Support" /><FAQs /><GetInTouch /><Footer /></div> <ScrollTopButton /></>} />
            <Route path="/Register" element={<><div className='Register'><RegisterPage /> <Footer /> </div> <ScrollTopButton /></>} />
            <Route path="/send-password-Email" element={<><div className='Register'><ResetPasswordPage /><Footer /></div> <ScrollTopButton /></>} />
            <Route path="/reset-password-Email/:uid/:token" element={<><div className='Register'><ResetPasswordEmailPage /> <Footer /></div><ScrollTopButton /></>} />
            <Route path="/SignIn" element={<><div className='SignIn'><SignInPage /><Footer /></div> <ScrollTopButton /></>} />
            {/* Authenticated routes */}
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" element={<><div className='DASHBOARD'> <DashboardLayout /> </div> </>} />
              <Route path="/dashboard/policies" element={<><div className='DASHBOARD'> <DashboardLayoutPolicies /></div></>} />
              <Route path="/dashboard/policies/apply" element={<><div className='DASHBOARD'> <DashboardLayoutApplyPolicy /></div></>} />
              <Route path="/dashboard/policies/manage" element={<><div className='DASHBOARD'> <DashboardManagePolicy /> </div></>} />
              <Route path="/dashboard/policies/renew" element={<><div className='DASHBOARD'> <DashboardProgress /></div></>} />
              <Route path="/dashboard/claims" element={<><div className='DASHBOARD'> <DashboardClaim /></div></>} />
              <Route path="/dashboard/claims/newclaim" element={<><div className='DASHBOARD'> <DashboardNewClaim /></div></>} />
              <Route path="/dashboard/claims/claimstatus" element={<><div className='DASHBOARD'> <DashboardClaimStatus /></div></>} />
              <Route path="/dashboard/claims/claimHistory" element={<><div className='DASHBOARD'> <DashboardClaimHistory /></div></>} />
              <Route path="/dashboard/pay" element={<><div className='DASHBOARD'> <DashboardPay /></div></>} />
              <Route path="/dashboard/profile" element={<><div className='DASHBOARD'> <DashboardProfile /></div></>} />
              <Route path="/dashboard/applied-policy" element={<><div className='DASHBOARD'> <DashboardAppliedPolicy /></div></>} />
            </Route>
            <Route path="*" element={<><div className='404Page'><Navbar /> <Headings title="Oops, we couldn't find that page." text="Sorry, it seems like you've wandered off the beaten path. Explore our mission elsewhere on our site, or navigate back to discover more about our initiatives and goals." link="/Home" linkText="CropShield home page" /><Footer /></div></>} />
          </Routes>
        </BrowserRouter>
      )}
    </>
  );
}

export default App;
