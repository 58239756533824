import React, { useEffect, useState } from 'react';
import './StreamlineData.css';

const StreamlineData = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await fetch('https://api.thingspeak.com/channels/2719501/fields/1.json?api_key=IJQ8DXIQWMZXR3NE&results=2');
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    if (!data) {
        return <div className="StreamlineData-main">Loading data...</div>;
    }

    return (
        <div className="StreamlineData-main">
            <div className="StreamlineData-card">
                <div className="card__title">{data.channel.name || "Weather Station Data"}</div>
                
                {/* Channel Information */}
                <div className="card__data">
                    <div className="card__right">
                        <div className="item">Channel ID</div>
                        <div className="item">Description</div>
                        <div className="item">Latitude</div>
                        <div className="item">Longitude</div>
                        <div className="item">Field1</div>
                        <div className="item">Field2</div>
                        <div className="item">Field3</div>
                        <div className="item">Field4</div>
                        <div className="item">Field5</div>
                        <div className="item">Created At</div>
                        <div className="item">Updated At</div>
                    </div>
                    <div className="card__left">
                        <div className="item">{data.channel.id}</div>
                        <div className="item">{data.channel.description}</div>
                        <div className="item">{data.channel.latitude}</div>
                        <div className="item">{data.channel.longitude}</div>
                        <div className="item">{data.channel.field1}</div>
                        <div className="item">{data.channel.field2}</div>
                        <div className="item">{data.channel.field3}</div>
                        <div className="item">{data.channel.field4}</div>
                        <div className="item">{data.channel.field5}</div>
                        <div className="item">{new Date(data.channel.created_at).toLocaleString()}</div>
                        <div className="item">{new Date(data.channel.updated_at).toLocaleString()}</div>
                    </div>
                </div>

                {/* Feed Entries */}
                {data.feeds.map(feed => (
                    <div className="card__data" key={feed.entry_id}>
                        <div className="card__right">
                            <div className="item">Entry ID</div>
                            <div className="item">Temperature (°C)</div>
                            <div className="item">Created At</div>
                        </div>
                        <div className="card__left">
                            <div className="item">{feed.entry_id}</div>
                            <div className="item">{feed.field1}°C</div>
                            <div className="item">{new Date(feed.created_at).toLocaleString()}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default StreamlineData;
