import React from 'react'
import "./UsdaCom.css"
import images from '../../constants/images'

function UsdaCom() {
    return (
        <>
            <div className='USDA-main-container'>
                <img src={images.USDAImage} alt="icon" />
                <p className='USDA-title'>Endowment Fund Secretariat</p>
            </div>
        </>
    )
}

export default UsdaCom