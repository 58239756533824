import React from 'react'
import './Dashtitle.css'

const Dashtitle = (props) => {
    return (
        <>
            <div className="Dashtitle-Headings">
                <p className='Dashtitle-title'>{props.title}</p>
            </div>
        </>
    )
}

export default Dashtitle