import React, { useState } from 'react';
import './Team.css';
import { teamMembers } from '../../constants/Teams';

const Team = () => {
    const [expandedMember, setExpandedMember] = useState(null);

    const handleMoreClick = (id) => {
        setExpandedMember(id === expandedMember ? null : id);
    };

    return (
        <div className="team-container">
            <div className="team-members">
                {teamMembers.map((member) => (
                    <div className="team-member" key={member.id}>
                        <div className="member-image-container">
                            <img
                                src={member.image}
                                alt={member.name}
                                className="team-image"
                            />
                            <div className="member-image-overlay"></div>
                        </div>
                        <div className="member-details">
                            <h3>{member.name}</h3>
                            <p>{member.position}</p>
                            {expandedMember === member.id && (
                                <>
                                    <p>Position at UAF: {member.positionUAF}</p>
                                    <p>
                                        Contact:{' '}
                                        <a
                                            href={`mailto:${member.contact}`}
                                            className="team-member-mail"
                                        >
                                            {member.contact}
                                        </a>
                                    </p>
                                </>
                            )}
                            <button
                                onClick={() => handleMoreClick(member.id)}
                                className="team-more-info"
                            >
                                {expandedMember === member.id ? 'Less' : 'More'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Team;
