import React from 'react'
import "./PageHeader.css"

const PageHeader = (props) => {
    return (
        <div className="PageHeader-container">
            <img src={props.image} alt="PageHeader Visiual" className='PageHeader-image' />
            <p className='PageHeader-title'>{props.title} <br /> </p>
        </div>
    )
}

export default PageHeader