import React from 'react'
import { Link } from 'react-router-dom';
import './DashSelection.css'
import { MdManageSearch } from "react-icons/md";
import { LuBaggageClaim } from "react-icons/lu";
const DashSelection = () => {
    return (
        <>
            <div className="DashSelection-container-main">
                <Link to="/dashboard/policies/manage" className="dashSelection-policy-management">
                    <MdManageSearch className='dashSelection-icons' />
                    <p className='dashselection-test'>Policy Management</p>
                </Link>
                <Link to="/dashboard/claims" className="dashSelection-policy-management">
                    <LuBaggageClaim className='dashSelection-icons' />
                    <p className='dashselection-test'>Claim Submission</p>
                </Link>
            </div>
        </>
    )
}

export default DashSelection
