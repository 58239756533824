import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import '../ManagePolicies/ManagePolicies.css'; 
import Dashtitle from '../Dashtitle/Dashtitle';
import axios from 'axios';

const ClaimStatus = () => {
    const [claims, setClaims] = useState([]);

    useEffect(() => {
        const fetchClaims = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get('https://api.cropinsuranceuaf.com/claims/manage-claims/', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setClaims(response.data);
            } catch (error) {
                console.error('Error fetching claims:', error);
            }
        };
        fetchClaims();
    }, []);

    const handleModifyClaim = (claim) => {
        console.log(`Modifying claim: ${claim.id}`);
    };

    const handleCancelClaim = (claim) => {
        console.log(`Cancelling claim: ${claim.id}`);
    };

    return (
        <>
            <Dashtitle title="Claim Status" />
            <div className="manage-policies-container">
                <div className="table-container">
                    <table className="policy-table">
                        <thead>
                            <tr>
                                <th>Policy </th>
                                <th>Policyholder</th>
                                <th>Date of Damage</th>
                                <th>Type of Damage</th>
                                <th>Extent of Damage</th>
                                <th>Status</th>
                                <th>Actions</th>
                                <th>Active</th> 
                            </tr>
                        </thead>
                        <tbody>
                            {claims.map((claim) => (
                                <tr key={claim.id}>
                                    <td>{claim.selectPolicy}</td>
                                    <td>{claim.farmerName}</td>
                                    <td>{claim.dateOfDamage}</td>
                                    <td>{claim.typeOfDamage}</td>
                                    <td>{claim.extentOfDamage}</td>
                                    <td>{claim.status}</td>
                                    <td className="action-buttons">
                                        <button className="modify-button" onClick={() => handleModifyClaim(claim)}>
                                            Modify <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button className="cancel-button" onClick={() => handleCancelClaim(claim)}>Cancel</button>
                                    </td>
                                    <td>
                                        {claim.status === 'APPROVED' ? (
                                            <span className="active-icon"><FontAwesomeIcon icon={faCheckCircle} /></span>
                                        ) : (
                                            <span className="cancelled-icon"><FontAwesomeIcon icon={faTimesCircle} /></span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ClaimStatus;
