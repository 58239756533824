import React from 'react'
import "./Hjoin.css"
import images from '../../constants/images'

const Hjoin = () => {
    return (
        <>
            <div className="hjoin-main-container">
                <div className="hjoin-leftside">
                    <img src={images.HjoinImage} alt="ThisisPic" />
                </div>
                <div className="hjoin-rightside">
                    <p className='hjoin-title'>Empowering Agriculture with Efficiency</p>
                    <div className="hjoin-point-div">
                        <p className='hjoin-point-title'>Streamlined and efficient</p>
                        <p className='hjoin-point-des'>Empowering farmers with a user-friendly platform for purchasing, managing, and accessing crop insurance policies.</p>
                    </div>
                    <div className="hjoin-point-div">
                        <p className='hjoin-point-title'>Swift claims processing</p>
                        <p className='hjoin-point-des'>Leveraging artificial intelligence to ensure timely and accurate compensation for crop damage or yield loss.</p>
                    </div>
                    <div className="hjoin-point-div">
                        <p className='hjoin-point-title'>Enhancing resilience in agriculture</p>
                        <p className='hjoin-point-des'>Addressing the challenges of unpredictable weather patterns and pests, CropShield safeguards the livelihoods of farmers.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hjoin 
