import React, { useState, useEffect } from 'react';
import axios from 'axios';
import images from '../../../constants/images';
import "../DashApplyPolicy/DashApplyPolicy.css";
import "./PaymentPage.css";

const PaymentPage = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formData, setFormData] = useState({
        method_type: '',
        card_number: '',
        card_holder_name: '',
        expiry_month: '',
        expiry_year: '',
        cvc: '',
        is_default: false
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [policyApplicationId, setPolicyApplicationId] = useState('40'); 
    const [amount, setAmount] = useState(100.00); 

    useEffect(() => {
        if (currentStep === 3) {
            createPaymentIntent();
        }
    }, [currentStep]);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleCardNumberChange = (event) => {
        const { value, selectionStart } = event.target;
        const cardNumber = value.replace(/\D/g, '');
        let formattedCardNumber = '';
        for (let i = 0; i < cardNumber.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedCardNumber += ' ';
            }
            formattedCardNumber += cardNumber[i];
        }
        const newPosition = selectionStart + Math.floor(selectionStart / 5);
        setFormData({
            ...formData,
            card_number: formattedCardNumber
        });
        event.target.setSelectionRange(newPosition, newPosition);
    };

    const handleAddRecipientClick = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePaymentMethodSubmit = async (e) => {
        e.preventDefault();

        const form = e.target;
        if (form.checkValidity()) {
            try {
                const response = await axios.post('https://api.cropinsuranceuaf.com/payment/payment-methods/', formData);
                console.log("Form submitted successfully:", response.data);
                setSuccessMessage('Payment method successfully updated.');
                setTimeout(() => {
                    setSuccessMessage('');
                }, 5000);
                setCurrentStep(3); 
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        }
    };

    const createPaymentIntent = async () => {
        try {
            const response = await axios.post('https://api.cropinsuranceuaf.com/payment/create-payment-intent/', {
                policy_application_id: policyApplicationId,
                amount: amount, // actual amount
            });
            setClientSecret(response.data.client_secret);
        } catch (error) {
            console.error("Error creating payment intent:", error);
        }
    };

    const handlePaymentSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://api.cropinsuranceuaf.com/payment/payments/', {
                card_number: formData.card_number.replace(/\s/g, ''), 
                expiry_month: formData.expiry_month,
                expiry_year: formData.expiry_year,
                cvc: formData.cvc,
                amount: amount, // actual amount
                user: 3, // Replace with actual user ID 
                policy_application: 40, // Replace with actual policy application ID 
                client_secret: clientSecret
            });

            if (response.data.status === 'succeeded') {
                setSuccessMessage('Payment Successful!');
            } else {
                setSuccessMessage('Payment failed.');
            }
        } catch (error) {
            console.error("Error processing payment:", error);
            setSuccessMessage('Payment failed.');
        }

        setTimeout(() => {
            setSuccessMessage('');
        }, 5000);
    };

    return (
        <div className="PaymentPage-main-container">
            {currentStep === 1 && (
                <>
                    <img src={images.Dashboard.PaymentPageIcon} alt="icon" />
                    <p className='PaymentPage-title'>You're steps away from making your payment</p>
                    <p className='PaymentPage-text'>To get started, add your bank details</p>
                    <div className="PaymentPage-hr"></div>
                    <button className='PaymentPage-button' type="button" onClick={handleAddRecipientClick}>Add Account</button>
                </>
            )}
            {currentStep === 2 && (
                <form onSubmit={handlePaymentMethodSubmit}>
                    <div className="form-group payment-info">
                        <div className='PaymentMethodIcon-images-div'></div>
                        <p className='apply-policy-form-section-headings'>Add Your Payment Account</p>

                        <label htmlFor="method_type">Payment Method <span>*</span></label>
                        <select
                            name="method_type"
                            id="method_type"
                            value={formData.method_type}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Payment Method</option>
                            <option value="Credit Card">Credit Card</option>
                            <option value="Debit Card">Debit Card</option>
                        </select>

                        {formData.method_type && (
                            <div className="card-details">
                                <label htmlFor="card_number">Card Number <span>*</span></label>
                                <input
                                    type="text"
                                    name="card_number"
                                    id="card_number"
                                    value={formData.card_number}
                                    onChange={handleCardNumberChange}
                                    placeholder="1234 5678 9012 3456"
                                    maxLength="19"
                                    required
                                    pattern="\d{4}\s?\d{4}\s?\d{4}\s?\d{4}"
                                    title="Please enter a valid 16-digit card number"
                                />

                                <label htmlFor="card_holder_name">Card Holder Name <span>*</span></label>
                                <input
                                    type="text"
                                    name="card_holder_name"
                                    id="card_holder_name"
                                    value={formData.card_holder_name}
                                    onChange={handleInputChange}
                                    placeholder="Enter card holder name"
                                    required
                                    maxLength="50"
                                    pattern="[A-Za-z\s]+"
                                    title="Please enter only letters and spaces"
                                    className="input-field"
                                />

                                <div className="expiry-cvv">
                                    <div className="expiry">
                                        <label htmlFor="expiry_month">Expiry Month <span>*</span></label>
                                        <input
                                            type="text"
                                            name="expiry_month"
                                            id="expiry_month"
                                            value={formData.expiry_month}
                                            onChange={handleInputChange}
                                            placeholder="MM"
                                            maxLength="2"
                                            required
                                        />
                                    </div>
                                    <div className="expiry">
                                        <label htmlFor="expiry_year">Expiry Year <span>*</span></label>
                                        <input
                                            type="text"
                                            name="expiry_year"
                                            id="expiry_year"
                                            value={formData.expiry_year}
                                            onChange={handleInputChange}
                                            placeholder="YYYY"
                                            maxLength="4"
                                            required
                                        />
                                    </div>

                                    <div className="cvc">
                                        <label htmlFor="cvc">CVC <span>*</span></label>
                                        <input
                                            type="password"
                                            pattern="[0-9]{3}"
                                            name="cvc"
                                            id="cvc"
                                            value={formData.cvc}
                                            onChange={handleInputChange}
                                            placeholder="Enter CVC"
                                            maxLength="3"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group-ckeckbox">
                                    <label htmlFor="is_default" className='containerr'>
                                        <input
                                            type="checkbox"
                                            name="is_default"
                                            id="is_default"
                                            checked={formData.is_default}
                                            onChange={handleInputChange}
                                        />
                                        <div className='checkmark'></div>
                                        Set as default payment method
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>
                    {successMessage && <div className="success-message" id='paymentmethod-success-message'>{successMessage}</div>}
                    <button className='PaymentPage-button' id='PaymentPage-button-id' type="submit">Add Payment</button>
                </form>
            )}
            {currentStep === 3 && (
                <form onSubmit={handlePaymentSubmit} className="stripe-containerrr">
                    <h3 className='apply-policy-form-section-headings'>Complete Your Payment</h3>
                    <div className="form-group payment-info">
                        <label htmlFor="card_number">Card Number <span>*</span></label>
                        <input
                            type="text"
                            name="card_number"
                            id="card_number"
                            value={formData.card_number}
                            onChange={handleCardNumberChange}
                            placeholder="1234 5678 9012 3456"
                            maxLength="19"
                            required
                            pattern="\d{4}\s?\d{4}\s?\d{4}\s?\d{4}"
                            title="Please enter a valid 16-digit card number"
                        />

                        <label htmlFor="card_holder_name">Card Holder Name <span>*</span></label>
                        <input
                            type="text"
                            name="card_holder_name"
                            id="card_holder_name"
                            value={formData.card_holder_name}
                            onChange={handleInputChange}
                            placeholder="Enter card holder name"
                            required
                            maxLength="50"
                            pattern="[A-Za-z\s]+"
                            title="Please enter only letters and spaces"
                            className="input-field"
                        />

                        <div className="expiry-cvv">
                            <div className="expiry">
                                <label htmlFor="expiry_month">Expiry Month <span>*</span></label>
                                <input
                                    type="text"
                                    name="expiry_month"
                                    id="expiry_month"
                                    value={formData.expiry_month}
                                    onChange={handleInputChange}
                                    placeholder="MM"
                                    maxLength="2"
                                    required
                                />
                            </div>
                            <div className="expiry">
                                <label htmlFor="expiry_year">Expiry Year <span>*</span></label>
                                <input
                                    type="text"
                                    name="expiry_year"
                                    id="expiry_year"
                                    value={formData.expiry_year}
                                    onChange={handleInputChange}
                                    placeholder="YYYY"
                                    maxLength="4"
                                    required
                                />
                            </div>

                            <div className="cvc">
                                <label htmlFor="cvc">CVC <span>*</span></label>
                                <input
                                    type="password"
                                    pattern="[0-9]{3}"
                                    name="cvc"
                                    id="cvc"
                                    value={formData.cvc}
                                    onChange={handleInputChange}
                                    placeholder="Enter CVC"
                                    maxLength="3"
                                    required
                                />
                            </div>
                        </div>

                        {successMessage && <div className="success-message" id='payment-success-message'>{successMessage}</div>}
                        <button className='PaymentPage-button' id='PaymentPage-button-id' type="submit">Complete Payment</button>
                    </div>
                </form>
            )}

        </div>
    );
};
export default PaymentPage;
