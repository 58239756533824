import React, { useState, useEffect } from 'react';
import '../ManagePolicies/ManagePolicies.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Dashtitle from '../Dashtitle/Dashtitle';
import axios from 'axios';

const ClaimHistory = () => {
    const [claimHistoryList, setClaimHistoryList] = useState([]);

    useEffect(() => {
        const fetchClaimHistory = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get('https://api.cropinsuranceuaf.com/claims/manage-claims/', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                // Filter claims with status 'APPROVED'
                const approvedClaims = response.data.filter(claim => claim.status === 'APPROVED');
                setClaimHistoryList(approvedClaims);
            } catch (error) {
                console.error('Error fetching claim history:', error);
            }
        };
        fetchClaimHistory();
    }, []);

    return (
        <>
            <Dashtitle title="Claim History" />
            <div className="manage-policies-container">
                <div className="table-container">
                    <table className="policy-table">
                        <thead>
                            <tr>
                                <th>Policy </th>
                                <th>Policyholder</th>
                                <th>Date of Damage</th>
                                <th>Type of Damage</th>
                                <th>Extent of Damage</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {claimHistoryList.map((claim, index) => (
                                <tr key={index}>
                                    <td>{claim.selectPolicy}</td>
                                    <td>{claim.farmerName}</td>
                                    <td>{claim.dateOfDamage}</td>
                                    <td>{claim.typeOfDamage}</td>
                                    <td>{claim.extentOfDamage}</td>
                                    <td>
                                        <span className={`status ${claim.status.toLowerCase()}`}>
                                            {claim.status}
                                            <FontAwesomeIcon
                                                className={`status-icon ${claim.status === 'APPROVED' ? 'approved' : 'rejected'}`}
                                                icon={claim.status === 'APPROVED' ? faCheckCircle : faTimesCircle}
                                            />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ClaimHistory;
