import React from 'react';
import './PoliciesCards.css';
import images from '../../constants/images'

const dummyData = [
    {
        id: 1,
        title: 'HarvestGuard Assurance',
        desc: 'Protect your crops with HarvestGuard, offering comprehensive coverage and peace of mind. Our innovative policy safeguards your investment against unpredictable risks, ensuring your harvest is secure.',
        link: '#',
        imageUrl: images.PoliciesPage.PoliciesPageImage1
    },
    {
        id: 2,
        title: 'CropShield Secure',
        desc: 'Safeguard your agricultural enterprise with CropShield, the ultimate solution for crop insurance. With advanced technology and personalized coverage options, CropShield provides reliable protection against unforeseen losses.',
        link: '#',
        imageUrl: images.PoliciesPage.PoliciesPageImage2
    },
    {
        id: 3,
        title: 'AgriGuard Plus',
        desc: 'Ensure the resilience of your farming operations with AgriGuard, a trusted name in crop insurance. With flexible coverage options and responsive claims processing, AgriGuard is your partner in agricultural security.',
        link: '#',
        imageUrl: images.PoliciesPage.PoliciesPageImage1
    },
    {
        id: 4,
        title: 'FarmShield Complete',
        desc: 'FarmShield offers comprehensive coverage tailored to your farm unique needs. With customizable policies and dedicated support, FarmShield protects your livelihood and investment in every season.',
        link: '#',
        imageUrl: images.PoliciesPage.PoliciesPageImage2
    },
    {
        id: 5,
        title: 'CropSafe Prime',
        desc: 'Stay protected with CropSafe, your reliable partner in crop insurance. Our proactive approach and responsive service ensure that your crops are safeguarded against unforeseen challenges, season after season.',
        link: '#',
        imageUrl: images.PoliciesPage.PoliciesPageImage1
    }
    

];

const PoliciesCards = () => {
    return (
        <>
            <h3 className='policiesCards-title'>Starting Your Secure Career</h3>
            <div className="PoliciesCards-main-container">
                {dummyData.map(card => (
                    <div className="card" key={card.id}>
                        <div className="image">
                            <img src={card.imageUrl} alt={card.title} />
                        </div>
                        <div className="content">
                            <a href={card.link}>
                                <span className="title">{card.title}</span>
                            </a>
                            <p className="desc">{card.desc}</p>
                            <a className="action" href={card.link}>
                                Find out more
                                <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PoliciesCards;
