import React from 'react'
import { Link } from 'react-router-dom'
import '../DashPolicies/DashPolicies.css'
import Dashtitle from '../Dashtitle/Dashtitle';
import { BsInfoCircleFill } from "react-icons/bs";
import { LuBaggageClaim } from "react-icons/lu";
import { TbStatusChange } from "react-icons/tb";
import { MdHistory } from "react-icons/md";

const DashClaims = () => {
    return (
        <>
            <Dashtitle title="Claim Process" />
            <div className="DashPolicies-main-container">
                <Link to="/dashboard/claims/newclaim">
                    <div className="DashPolicies-Box">
                        <div className="DashPolicies-Box-small-icon">
                            <BsInfoCircleFill className="DashPolicies-Box-side-icon" />
                        </div>
                        <div className="DashPolicies-Box-main-icon">
                            <LuBaggageClaim />
                        </div>
                        <div className="DashPolicies-Box-text">
                            <p>Submit New Claim</p>
                        </div>
                    </div>
                </Link>
                <Link to="/dashboard/claims/claimstatus">
                    <div className="DashPolicies-Box">
                        <div className="DashPolicies-Box-small-icon">
                            <BsInfoCircleFill className="DashPolicies-Box-side-icon" />
                        </div>
                        <div className="DashPolicies-Box-main-icon">
                            <TbStatusChange  />
                        </div>
                        <div className="DashPolicies-Box-text">
                            <p>Claim Status</p>
                        </div>
                    </div>
                </Link>
                <Link to="/dashboard/claims/claimHistory">
                    <div className="DashPolicies-Box">
                        <div className="DashPolicies-Box-small-icon">
                            <BsInfoCircleFill className="DashPolicies-Box-side-icon" />
                        </div>
                        <div className="DashPolicies-Box-main-icon">
                            < MdHistory />
                        </div>
                        <div className="DashPolicies-Box-text">
                            <p>Claim History</p>
                        </div>
                    </div>
                </Link>
            </div>

        </>

    )
}

export default DashClaims