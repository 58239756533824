import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheckCircle, faTimesCircle, faTrashAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import './ManagePolicies.css';
import Dashtitle from '../Dashtitle/Dashtitle';
const ManagePolicies = () => {
    const [policies, setPolicies] = useState([]);

    useEffect(() => {
        fetchPolicies();
    }, []);

    const fetchPolicies = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get('https://api.cropinsuranceuaf.com/insurance/manage-policies/', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const policiesData = await Promise.all(response.data.map(async (policy) => {
                const premiumDeductible = await fetchPolicyPremiumDeductible(policy.selectPolicy);
                const paymentsMade = await fetchPaymentsMade(policy.id);
                return { ...policy, premium: premiumDeductible.premium, deductible: premiumDeductible.deductible, paymentsMade };
            }));
            setPolicies(policiesData);
        } catch (error) {
            console.error('Error fetching policies:', error);
        }
    };

    const fetchPolicyPremiumDeductible = async (selectedPolicy) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`https://api.cropinsuranceuaf.com/insurance/policy-premium-deductible?selectPolicy=${selectedPolicy}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching policy premium and deductible:', error);
            return {};
        }
    };

    const fetchPaymentsMade = async (policyId) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await axios.get(`https://api.cropinsuranceuaf.com/insurance/policies/${policyId}/payment-count/`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data.paymentMade;
        } catch (error) {
            console.error('Error fetching payments made for policy:', error);
            return 0;
        }
    };

    const handleRenew = (policy) => {
        console.log(`Renewing policy: ${policy.selectPolicy}`);
    };

    const handleModifyCoverage = (policy) => {
        console.log(`Modifying coverage for policy: ${policy.selectPolicy}`);
    };

    const handleCancelPolicy = (policy) => {
        console.log(`Cancelling policy: ${policy.selectPolicy}`);
    };

    const handleDeletePolicy = (policy) => {
        console.log(`Deleting policy: ${policy.selectPolicy}`);
    };

    return (
        <>
            <Dashtitle title="Manage Policies" />
            <div className="manage-policies-container">
                <div className="table-container">
                    <table className="policy-table">
                        <thead>
                            <tr>
                                <th>Policy</th>
                                <th>ID</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>Coverage</th>
                                <th>Premium</th>
                                <th>Deductible</th>
                                <th>Payments Made</th>
                                <th>Actions</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody>
                            {policies.map((policy) => (
                                <tr key={policy.id}>
                                    <td>{policy.selectPolicy}</td>
                                    <td>{policy.display_id}</td>
                                    <td>{policy.status}</td>
                                    <td>{policy.startDate}</td>
                                    <td>{policy.coverageAmount}</td>
                                    <td>{policy.premium}</td>
                                    <td>{policy.deductible}</td>
                                    <td>
                                        {Array.isArray(policy.paymentsMade) ? (
                                            policy.paymentsMade.map((payment, index) => (
                                                <div key={index}>{payment}</div>
                                            ))
                                        ) : (
                                            <div>{policy.paymentsMade}</div>
                                        )}
                                    </td>
                                    <td className="action-buttons">
                                        {policy.status === 'Pending Renewal' && (
                                            <>
                                                <button className="renew-button" onClick={() => handleRenew(policy)}>Renew</button>
                                                <button className="modify-button" onClick={() => handleModifyCoverage(policy)}>
                                                    Modify <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                                <button className="delete-button" onClick={() => handleDeletePolicy(policy)}>
                                                    Delete <FontAwesomeIcon icon={faTrashAlt} />
                                                </button>
                                            </>
                                        )}
                                        {policy.status === 'Active' && (
                                            <button className="cancel-button" onClick={() => handleCancelPolicy(policy)}>Cancel</button>
                                        )}
                                        <button className="more-button">
                                            More <FontAwesomeIcon icon={faEllipsisH} />
                                        </button>
                                    </td>
                                    <td>
                                        {policy.status === 'APPROVED' && (
                                            <span className="active-icon"><FontAwesomeIcon icon={faCheckCircle} /></span>
                                        )}
                                        {policy.status === 'PENDING' && (
                                            <span className="pending-icon"><FontAwesomeIcon icon={faEllipsisH} /></span>
                                        )}
                                        {policy.status === 'DENIED' && (
                                            <span className="denied-icon"><FontAwesomeIcon icon={faTimesCircle} /></span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ManagePolicies;
