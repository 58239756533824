import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./ProfileBlock.css";

const ProfileBlock = () => {
    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        bio: "",
        profile_picture: {
            custom_user: null,
            image: null
        }
    });
    const [imageFile, setImageFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [fileInputKey, setFileInputKey] = useState(0);

    useEffect(() => {
        fetchUserDataFromAPI();
    },[]);

    const fetchUserDataFromAPI = async () => {
        const token = localStorage.getItem('accessToken');

        try {
            const response = await axios.get('https://api.cropinsuranceuaf.com/auth/profile-data/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const updatedUserData = {
                ...userData,
                ...response.data
            };

            setUserData(updatedUserData);
        } catch (error) {
            console.error("Error fetching user data: ", error);
            setError("Failed to fetch user data");
        }
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setImageFile(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsUploading(true);

        const formData = new FormData();
        formData.append('image', imageFile);

        try {
            const token = localStorage.getItem('accessToken');
            const url = 'https://api.cropinsuranceuaf.com/auth/profile-picture/';
            await axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            fetchUserDataFromAPI();
        } catch (error) {
            console.error("Error uploading profile picture: ", error);
            setError("Failed to upload profile picture");
        } finally {
            setIsUploading(false);
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUserData({
            ...userData,
            [name]: value
        });
    };

    const handleUpdateProfile = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const url = 'https://api.cropinsuranceuaf.com/auth/profile-data/';
            await axios.put(url, userData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            fetchUserDataFromAPI();
        } catch (error) {
            console.error("Error updating profile: ", error);
            setError("Failed to update profile");
        }
    };

    return (
        <div className="profileBlock-main-container">
            <div className="profileBlock-container">
                <div className="profileBlock-image-container">

                    <form className="file-upload-form">
                        <label htmlFor={`fileInput${fileInputKey}`} className="file-upload-label" style={{ backgroundImage: `url(https://api.cropinsuranceuaf.com${userData.profile_picture && userData.profile_picture.image})` }}>
                            <div className="file-upload-design" >
                                <svg viewBox="0 0 640 512" height="1em">
                                    <path
                                        d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"
                                    ></path>
                                </svg>
                                <p>Drag and Drop</p>
                                <p>or</p>
                                <span className="browse-button">Browse file</span>
                            </div>
                            <input
                                id={`fileInput${fileInputKey}`}
                                key={fileInputKey}
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    handleFileInputChange(e);
                                    setFileInputKey((prevKey) => prevKey + 1);
                                }}
                            />
                        </label>
                    </form>
                    <button className="browse-button" id="browse-button-id" onClick={handleSubmit} disabled={!imageFile || isUploading}>Upload Image</button>
                    {error && <p className="error-message-for-upload-profile-image">{error}</p>}
                </div>
                <div className="profileBlock-text-container-main">
                    <div className="form-group farmer-info active" id='form-group-profile-info'>
                        <p className='apply-policy-form-section-headings'>Your Profile Info <small>(Keep your profile updated)</small></p>
                        <label htmlFor="first_name">First Name <span>*</span></label>
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            value={userData.first_name}
                            onChange={handleChange}
                            required
                            placeholder="Enter your first name"
                            disabled
                        />
                        <label htmlFor="last_name">Last Name <span>*</span></label>
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            value={userData.last_name}
                            onChange={handleChange}
                            required
                            placeholder="Enter your last name"
                            disabled
                        />
                        <label htmlFor="email">Email <span>*</span></label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={userData.email}
                            onChange={handleChange}
                            required
                            placeholder="Enter your email address"
                            disabled
                        />
                        <label htmlFor="address">Address</label>
                        <input
                            type="text"
                            name="address"
                            id="address"
                            value={userData.address}
                            onChange={handleChange}
                            placeholder="Enter your address"
                        />
                        <label htmlFor="bio">Bio</label>
                        <textarea
                            name="bio"
                            id="bio"
                            value={userData.bio}
                            onChange={handleChange}
                            placeholder="Enter your bio"
                        ></textarea>
                        <button className="update-button-profile" onClick={handleUpdateProfile}>Update Profile</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileBlock;
