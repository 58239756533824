import React, { useState, useEffect } from 'react';
import './DashApplyPolicy.css';
import axios from 'axios';

const DashApplyPolicy = () => {
    const [currentStep, setCurrentStep] = useState(1);
    // UPLOADING IMAGE States START
    const [selectedImage1, setSelectedImage1] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [selectedImage3, setSelectedImage3] = useState(null);
    const [selectedImage4, setSelectedImage4] = useState(null);
    // UPLOADING IMAGE States END
    const [isSubmitted, setIsSubmitted] = useState(false);
    // Add state variables for premium and deductible
    const [adminDecidedPremium, setAdminDecidedPremium] = useState('');
    const [adminDecidedDeductible, setAdminDecidedDeductible] = useState('');
    const [loading, setLoading] = useState(true); 
    const [formData, setFormData] = useState({
        // PERSONAL INFORMATION
        farmerName: '',
        dateOfBirth: '',
        gender: '',
        cnic: '',
        countryCode: '',
        phoneNumber: '',
        email: '',
        address: '',
        passportPicture1: '',
        cnicPicture1: '',
        cnicPicture2: '',
        domicilePicture: '',
        // FARM INFORMATION
        farmAddress: '',
        cropsInsured: '',
        otherCrop: '',
        acreagePlanted: '',
        cropVariety: '',
        plantingDate: '',
        // INSURANCE DETAILS
        selectPolicy: '',
        coverageAmount: '',
        startDate: '',
        // endDate: '',
        premium: '',
        deductible: '',
        riskFactor: '',
        additionalComments: '',
        // PAYMENT
        paymentMethod: '',
        cardNumber: '',
        cardHolderName: '',
        expiryDate: '',
        cvc: '',
        agreeTerms: false,
    });
    const [userData, setUserData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        address: "",
        bio: "",
        profile_picture: {
            custom_user: null,
            image: null
        }
    });
    const [error, setError] = useState('');
    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = async () => {
        const token = localStorage.getItem('accessToken');

        try {
            const response = await axios.get('https://api.cropinsuranceuaf.com/auth/profile-data/', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const updatedUserData = {
                ...userData,
                ...response.data
            };

            setUserData(updatedUserData);
        } catch (error) {
            console.error("Error fetching user data: ", error);
            setError("Failed to fetch user data");
        }
    };


    const handleChangePr = (event) => {
        const { name, value } = event.target;
        if (name === 'selectPolicy') {
            // Update the selected policy
            setFormData({
                ...formData,
                [name]: value,
            });
            // Fetch premium and deductible values based on the selected policy
            fetchAdminDecidedValues(value);
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const fetchAdminDecidedValues = async (selectedPolicy) => {
        try {
            const response = await axios.get(`https://api.cropinsuranceuaf.com/insurance/policy-premium-deductible?selectPolicy=${selectedPolicy}`);
            const { premium, deductible } = response.data;
            setAdminDecidedPremium(premium);
            setAdminDecidedDeductible(deductible);
        } catch (error) {
            console.error('Error fetching admin-decided values:', error);
        } finally {
            setLoading(false); 
        }
    };

    const handleChange = (event) => {
        const { name, value, id } = event.target;
        if (name === 'farmLocation') {
            setFormData({
                ...formData,
                farmLocation: {
                    ...formData.farmLocation,
                    [id]: value,
                },
            });
        } else if (name === 'proofOfOwnership' || name === 'additionalDocuments') {
            setFormData({
                ...formData,
                [name]: event.target.files[0],
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const handleCardNumberChange = (event) => {
        const { value, selectionStart } = event.target;
        const cardNumber = value.replace(/\D/g, '');
        let formattedCardNumber = '';
        for (let i = 0; i < cardNumber.length; i++) {
            if (i > 0 && i % 4 === 0) {
                formattedCardNumber += ' ';
            }
            formattedCardNumber += cardNumber[i];
        }
        const newPosition = selectionStart + Math.floor(selectionStart / 5);
        setFormData({
            ...formData,
            cardNumber: formattedCardNumber
        });
        event.target.setSelectionRange(newPosition, newPosition);
    };


    const today = new Date().toISOString().split('T')[0]; 
    const minEndDate = new Date();
    minEndDate.setFullYear(minEndDate.getFullYear() + 1);
    const minEndDateISO = minEndDate.toISOString().split('T')[0];
    formData.startDate = today;


    const handleImageChange = (event, setSelectedImage) => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            console.error('No file selected');
            return;
        }
        if (file.size > 1024 * 1024 * 5) { // 5 MB limit
            alert('File size too large, maximum 5 MB allowed!');
            return;
        }
        const reader = new FileReader();
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);

        setFormData({
            ...formData,
            [event.target.name]: file,
        });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.post('https://api.cropinsuranceuaf.com/insurance/apply-policy/', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Form submitted successfully:', response.data);
            setFormData({
                // PERSONAL INFORMATION
                farmerName: '',
                dateOfBirth: '',
                gender: '',
                cnic: '',
                countryCode: '',
                phoneNumber: '',
                email: '',
                address: '',
                passportPicture1: '',
                cnicPicture1: '',
                cnicPicture2: '',
                domicilePicture: '',
                // FARM INFORMATION
                farmAddress: '',
                cropsInsured: '',
                otherCrop: '',
                acreagePlanted: '',
                cropVariety: '',
                plantingDate: '',
                // INSURANCE DETAILS
                selectPolicy: '',
                coverageAmount: '',
                startDate: '',
                // endDate: '',
                premium: '',
                deductible: '',
                riskFactor: '',
                additionalComments: '',
                // PAYMENT
                paymentMethod: '',
                cardNumber: '',
                cardHolderName: '',
                expiryDate: '',
                cvc: '',
                agreeTerms: false,
            });
            setIsSubmitted(true); 
            setError(''); 
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsSubmitted(false); 
            setError('Failed to submit the form'); 
        }
    };

    const nextStep = () => {
        const requiredFields = document.querySelectorAll('.form-group.active [required]');
        let isValid = true;
        requiredFields.forEach(field => {
            if (!field.value.trim()) {
                isValid = false;
                setError('Please fill out all required fields.');
            }
        });

        if (isValid) {
            setCurrentStep(currentStep + 1);
            setError('');
        }
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
        setError('');
    };
    // const handleCheckBoxChange = (e) => {
    //     setCheckBoxError(!e.target.checked);
    // };

    const renderSection = (step) => {
        switch (step) {
            case 1:
                //  -------------PERSONAL INFORMATION STARTS--------------- 
                return (
                    <div className="form-group farmer-info active">
                        <p className='apply-policy-form-section-headings'>Personal Information</p>
                        <label htmlFor="farmerName">Full Name <span>*</span><br /><small>(As per your CNIC)</small></label>
                        <input
                            type="text"
                            name="farmerName"
                            id="farmerName"
                            value={formData.farmerName}
                            onChange={handleChange}
                            required
                            placeholder="e.g., Faizan Ahmad"
                            maxlength="50"
                        />
                        <label htmlFor="dateOfBirth">Date of Birth <span>*</span></label>
                        <input
                            type="date"
                            name="dateOfBirth"
                            id="dateOfBirth"
                            value={formData.dateOfBirth}
                            onChange={handleChange}
                            required
                            placeholder="Select your date of birth"
                        />
                        <label htmlFor="gender">Gender <span>*</span> <br /> <small></small></label>
                        <select
                            name="gender"
                            id="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            className="form-input"
                            required
                        >
                            <option value="" disabled>Select your gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="agender">Agender</option>
                            <option value="androgyne">Androgyne</option>
                            <option value="bigender">Bigender</option>
                            <option value="genderfluid">Genderfluid</option>
                            <option value="genderqueer">Genderqueer</option>
                            <option value="nonbinary">Non-binary</option>
                            <option value="prefer not to say">Prefer not to say</option>

                        </select>
                        <label htmlFor="cnic">CNIC <span>*</span><br /><small>(Ex: XXXXXXXXXXXXX)</small></label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            inputMode="numeric"
                            name="cnic"
                            id="cnic"
                            value={formData.cnic}
                            onChange={handleChange}
                            required
                            maxLength="13"
                            minLength="13"
                            placeholder="e.g., 3310088776665"
                        />

                        <label htmlFor="contactNumber">Contact Number <span>*</span></label>
                        <div class="phone-input">
                            <select
                                name="countryCode"
                                id="countryCode"
                                value={formData.countryCode}
                                onChange={handleChange}
                                required
                                class="select2"
                            >
                                <option value="+1">+1 (USA)</option>
                                <option value="+44">+44 (UK)</option>
                                <option value="+92">+92 (Pakistan)</option>
                                <option value="+970">+970 (Palestine)</option>
                                <option value="+20">+20 (Egypt)</option>
                                <option value="+962">+962 (Jordan)</option>
                                <option value="+971">+971 (UAE)</option>
                            </select>
                            <input
                                type="tel"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                required
                                maxLength="10"
                                placeholder="Enter your phone number (e.g., 1234567890)"
                            />
                        </div>
                        <label htmlFor="email">Email <span>*</span></label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                            // disabled
                            required
                            placeholder="Enter your email address"
                        />
                        <label htmlFor="address">Residential Address <span>*</span></label>
                        <input
                            name="address"
                            id="address"
                            value={formData.address}
                            onChange={handleChange}
                            required
                            rows="2"
                            minLength="20"
                            maxLength="100"
                            placeholder="Enter your complete address"
                        />


                    </div>
                );
            //  -------------PERSONAL INFORMATION END--------------- 
            case 2:
                //  ------------- CROP INFORMATION STARTS---------------
                return (
                    <div className="form-group crop-info active">
                        <p className='apply-policy-form-section-headings'>Farm Information</p>
                        <label htmlFor="farmAddress">Farm Address <span>*</span></label>
                        <input
                            name="farmAddress"
                            id="farmAddress"
                            value={formData.farmAddress}
                            onChange={handleChange}
                            required
                            rows="2"
                            minLength="20"
                            maxLength="100"
                            placeholder="Enter your complete Farm address"
                        />

                        <label htmlFor="cropsInsured">Type of Crop(s) Insured <span>*</span></label>
                        <select
                            name="cropsInsured"
                            id="cropsInsured"
                            value={formData.cropsInsured}
                            onChange={handleChange}
                            className="form-input"
                            required
                        >
                            <option value="">Select Crop</option>
                            <option value="Wheat">Wheat</option>
                            <option value="Rice">Rice</option>
                            <option value="Cotton">Cotton</option>
                            <option value="Sugarcane">Sugarcane</option>
                            <option value="Other">Other</option>
                        </select>
                        {formData.cropsInsured === 'Other' && (
                            <div>
                                <label htmlFor="otherCrop">If Other <span>*</span></label>
                                <input
                                    type="text"
                                    name="otherCrop"
                                    id="otherCrop"
                                    value={formData.otherCrop}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        )}

                        <label htmlFor="acreagePlanted">Acreage Planted for Each Crop in acres <span>*</span></label>
                        <input
                            type="number"
                            name="acreagePlanted"
                            id="acreagePlanted"
                            value={formData.acreagePlanted}
                            onChange={handleChange}
                            required
                            min="0"
                            step="0.01"
                            placeholder="Enter the total acreage planted for this crop in acres"
                            title="Please enter a valid number (e.g., 10.5)"
                        />
                        <label htmlFor="cropVariety">Crop Variety or Strain (optional)</label>
                        <input
                            type="text"
                            name="cropVariety"
                            id="cropVariety"
                            value={formData.cropVariety}
                            onChange={handleChange}
                            placeholder="Enter the variety or strain of the crop"
                        />
                        <label htmlFor="plantingDate">Planting Date(s) <span>*</span></label>
                        <input
                            type="date"
                            name="plantingDate"
                            id="plantingDate"
                            value={formData.plantingDate}
                            onChange={handleChange}
                            min="2023-01-01"
                            required

                        />
                    </div>
                );
            //  ------------- CROP INFORMATION END---------------
            // --------------- INSURANCE DETAILS STARTS ----------
            case 3:
                return (
                    <div className="form-group farmer-info active">
                        <p className='apply-policy-form-section-headings'>Insurance Details</p>
                        <label htmlFor="selectPolicy">Select Policy <span>*</span> <br /> <small>(Carefully select the policy in which you want to enroll before applying)</small></label>
                        <select
                            name="selectPolicy"
                            id="selectPolicy"
                            value={formData.selectPolicy}
                            onChange={handleChangePr}
                            className="form-input"
                            required
                        >
                            <option value="" disabled>Select Policy</option>
                            <option value="HarvestGuard_Assurance">HarvestGuard Assurance</option>
                            <option value="CropShield_Secure">CropShield Secure</option>
                            <option value="AgriGuard_Plus">AgriGuard Plus</option>
                            <option value="FarmShield_Complete">FarmShield Complete</option>
                            <option value="CropSafe_Prime">CropSafe Prime</option>

                        </select>

                        <label htmlFor="premium">Premium</label>
                        <input
                            type="text"
                            name="premium"
                            id="premium"
                            value={adminDecidedPremium}
                            readOnly
                        />
                        <label htmlFor="deductible">Deductible</label>
                        <input
                            type="text"
                            name="deductible"
                            id="deductible"
                            value={adminDecidedDeductible}
                            readOnly
                        />

                        <label htmlFor="startDate">Policy Start Date <span>*</span></label>
                        <input
                            type="date"
                            name="startDate"
                            id="startDate"
                            value={formData.startDate}
                            onChange={handleChange}
                            required
                            disabled
                        />

                        <label htmlFor="coverageAmount">Coverage Amount <span>*</span></label>
                        <input
                            type="number"
                            name="coverageAmount"
                            id="coverageAmount"
                            value={formData.coverageAmount}
                            onChange={handleChange}
                            placeholder="Enter desired coverage amount"
                            required
                        />
                        <label htmlFor="riskFactor">Risk Factor <span>*</span> <br /> <small>(Select the risk that effect the most)</small></label>
                        <select
                            name="riskFactor"
                            id="riskFactor"
                            value={formData.policySelect}
                            onChange={handleChange}
                            className="form-input"
                            required
                        >
                            <option value="" disabled>Select Risk</option>
                            <option value="Floods">Floods</option>
                            <option value="Droughts">Droughts</option>
                            <option value="Hailstorms">Hailstorms</option>
                            <option value="CommonPest1">Pest and Disease Risks</option>
                        </select>

                        <label htmlFor="additionalComments">Additional Comments or Notes</label>
                        <textarea
                            name="additionalComments"
                            id="additionalComments"
                            value={formData.additionalComments}
                            onChange={handleChange}
                            placeholder="Enter any specific concerns or requirements"
                            rows="4"
                            cols="50"
                        ></textarea>

                    </div>
                );
            // --------------- INSURANCE DETAILS END ------------------
            // --------------- INSURANCE PAYMENT START ------------------
            case 4:
                return (
                    <>
                        <div className="form-group payment-info">
                            <p className='apply-policy-form-section-headings'>Payment Information</p>
                            <label htmlFor="paymentMethod">Payment Method <span>*</span></label>
                            <select
                                name="paymentMethod"
                                id="paymentMethod"
                                value={formData.paymentMethod}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Select Payment Method</option>
                                <option value="credit_card">Credit Card</option>
                                <option value="debit_card">Debit Card</option>
                            </select>
                            {formData.paymentMethod && (
                                <div className="card-details">
                                    <label htmlFor="cardNumber">Card Number <span>*</span></label>
                                    <input
                                        type="text"
                                        name="cardNumber"
                                        id="cardNumber"
                                        value={formData.cardNumber}
                                        onChange={handleCardNumberChange}
                                        placeholder="1234 5678 9012 3456"
                                        maxLength="19" // Maximum length for the card number including spaces
                                        required
                                        pattern="\d{4}\s?\d{4}\s?\d{4}\s?\d{4}" // Pattern for 16 digits with optional spaces

                                        title="Please enter a valid 16-digit card number"
                                    />

                                    <label htmlFor="cardHolderName">Card Holder Name <span>*</span></label>
                                    <input
                                        type="text"
                                        name="cardHolderName"
                                        id="cardHolderName"
                                        value={formData.cardHolderName}
                                        onChange={handleChange}
                                        placeholder="Enter card holder name"
                                        required
                                        maxLength="50"
                                        pattern="[A-Za-z\s]+"
                                        title="Please enter only letters and spaces"
                                        className="input-field"
                                    />
                                    <div class="expiry-cvv">
                                        <div class="expiry">
                                            <label for="expiryDate">Expiry Date <span>*</span></label>
                                            <input
                                                type="month"
                                                name="expiryDate"
                                                id="expiryDate"
                                                value={formData.expiryDate}
                                                onChange={handleChange}
                                                title="Please select the expiry date"
                                                min="2024-01"
                                                required
                                            />
                                        </div>

                                        <div class="cvc">
                                            <label for="cvc">CVC <span>*</span></label>
                                            <input
                                                type="password"
                                                pattern="[0-9]{3}"
                                                name="cvc"
                                                id="cvc"
                                                value={formData.cvv}
                                                onChange={handleChange}
                                                placeholder="Enter CVC"
                                                title="Please enter a valid CVC (3 digits)"
                                                maxlength="3"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <p className='apply-policy-form-section-headings'>Upload Documents</p>

                            <div className="upload-picture">
                                <label htmlFor="passportPicture1" className="upload-label">Your Picture<span>*</span><br /><small>(Upload a Passport Size Picture with a White/Blue Background)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="passportPicture1"
                                    id="passportPicture1"
                                    onChange={(event) => handleImageChange(event, setSelectedImage1)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage1 && (
                                    <div className="image-preview">
                                        <img src={selectedImage1} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>
                            <div className="upload-picture">
                                <label htmlFor="cnicPicture1" className="upload-label">Your CNIC Front<span>*</span><br /><small>(Upload CNIC Front-Side with clear details)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="cnicPicture1"
                                    name="cnicPicture1"
                                    onChange={(event) => handleImageChange(event, setSelectedImage2)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage2 && (
                                    <div className="image-preview">
                                        <img src={selectedImage2} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>

                            <div className="upload-picture">
                                <label htmlFor="cnicPicture2" className="upload-label">Your CNIC Back<span>*</span><br /><small>(Upload CNIC Front-Side with clear details)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="cnicPicture2"
                                    name="cnicPicture2"
                                    onChange={(event) => handleImageChange(event, setSelectedImage3)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage3 && (
                                    <div className="image-preview">
                                        <img src={selectedImage3} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>
                            <div className="upload-picture">
                                <label htmlFor="domicilePicture" className="upload-label">domicilePicture<span>*</span><br /><small>(Upload CNIC Front-Side with clear details)</small></label>
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="domicilePicture"
                                    name="domicilePicture"
                                    onChange={(event) => handleImageChange(event, setSelectedImage4)}
                                    required
                                    className="upload-input"
                                />
                                {selectedImage4 && (
                                    <div className="image-preview">
                                        <img src={selectedImage4} alt="Preview" className="preview-image" />
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="checkbox-wrapper-46">
                            <input
                                type="checkbox"
                                id="cbx-46"
                                className="inp-cbx"
                                required
                            />
                            <label htmlFor="cbx-46" className="cbx">
                                <span>
                                    <svg viewBox="0 0 12 10" height="10px" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span>
                                <span className='check-box-text-field'>I authorize CropShield to collect, process, and store my personal information in accordance with the privacy policy and terms of service. I also authorize the payment of the specified premium amount for the selected crop insurance policy through the provided payment method.</span>
                            </label>
                        </div>
                    </>
                );
            // --------------- PAYMENT INFORMATION END----------
            default:
                return null;
        }
    };

    return (
        <>
            <div className="ApplyPolicy-main-container">
                <form className="apply-policy-form" onSubmit={handleSubmit}>
                    {/* --------------TOP SECTIONS DISPLAY---------------- */}
                    <div className="apply-policy-section-name">
                        <ul>
                            <li className={currentStep === 1 ? 'active' : ''} onClick={() => setCurrentStep(1)}>
                                PERSONAL INFORMATION
                                {currentStep > 1 && <span className="icon">{'>'}</span>}
                            </li>
                            <li className={currentStep === 2 ? 'active' : ''} onClick={() => setCurrentStep(2)}>
                                FARM INFORMATION
                                {currentStep > 2 && <span className="icon">{'>'}</span>}
                            </li>
                            <li className={currentStep === 3 ? 'active' : ''} onClick={() => setCurrentStep(3)}>
                                INSURANCE DETAILS
                                {currentStep > 3 && <span className="icon">{'>'}</span>}
                            </li>
                            <li className={currentStep === 4 ? 'active' : ''} onClick={() => setCurrentStep(4)}>
                                PAYMENT & DOC
                            </li>
                        </ul>
                    </div>
                    {/* --------------CONTROL BUTTONS---------------- */}
                    {renderSection(currentStep)}
                    {isSubmitted && (
                        <p className="success-message" id='form-message'>Form submitted successfully!</p>
                    )}
                    {error && <p className="error-message" id='form-message'>{error}</p>}
                    <div className="form-buttons">
                        {currentStep !== 1 && (
                            <button type="button" onClick={prevStep} id='apply-policy-previous-button'>Previous</button>
                        )}
                        {currentStep < 4 && (
                            <button type="submit" onClick={nextStep} id='apply-policy-next-button'>Next</button>
                        )}
                        {currentStep === 4 && (
                            <button type="submit">Submit</button>
                        )}
                    </div>

                </form>
            </div>
        </>
    );
};

export default DashApplyPolicy;
